import React,{useEffect,useState} from "react";
import ContactForm from "./ContactForm";

// core components
import DemoNavbar from "components/DemoNavbar";
import HeroSection from "components/HeroSection";
import MainContent from "./MainContent";
import OnTopBtn from "components/OnTopBtn";
import SimpleFooter from "components/SimpleFooter";


function Contact() {

  const [onTop, setOnTop] = useState(false)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
    setOnTop(false)
  }, [onTop])

    
  return (
    <>
      <DemoNavbar />
      <HeroSection/>
      <MainContent/>
      <ContactForm/>
      <OnTopBtn setOnTop={setOnTop}/>
      <SimpleFooter/>
    </>
  );
}


export default Contact;

import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

function Loader2() {

    const divStyles = {
        display: 'grid',
        placeItems: 'center',
        height: '100vh',
    }

    return (
        <div style={divStyles}>
            <PulseLoader color="#5e72e4" className="d-flex" />
        </div>
    );
}

export default Loader2;

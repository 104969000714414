import Spinner from "react-bootstrap/Spinner";

function Loder({ loaded }) {
  const loderStyles = {
    color: "#fff",
    zIndex: "9999",
    backgroundColor: "rgba(0, 0, 255, 0.1)", // Adjust the alpha (last value) for blue opacity
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  return (
    <>
      {loaded ? (
        <div style={loderStyles}>
          <Spinner animation="border" role="status"></Spinner>
          <span className="visually-hidden mt-3">Loading......</span>
        </div>
      ) : null}
    </>
  );
}

export default Loder;


import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as _ from 'lodash';
import { Spinner } from "react-bootstrap";
import { HiUserCircle } from "react-icons/hi"
import {
  Container,
} from "reactstrap";

import TextError from "components/TextError";
import { SignIn } from "services/api/auth";

function Login() {
  const [usernameVal, setUsernameVal] = useState(false)
  const [validate, setValidate] = useState(false);
  const [spinnerBtn, setSpinnerBtn] = useState(false)

  const [modelData, setModelData] = useState({
    username: "",
    password: ""
  });

  const history = useHistory()

  const handleLogin = async (e) => {
    e.preventDefault();
    let checkData = { ...modelData }
    const notEmpty = await _.values(checkData).every(_.negate(_.isEmpty));
      setValidate(true)
    setSpinnerBtn(true)
    if (notEmpty == true) {
      const response = await SignIn(checkData);
      if (response?.data?.success) {
        setValidate(true)
        setSpinnerBtn(false)
        sessionStorage.setItem("token", response?.data?.data.token)
        sessionStorage.setItem("username", response?.data?.data.username)
        sessionStorage.setItem("userId", response?.data?.data._id)
        history.push({ pathname: '/dashboard' })
      } else {
        history.push({ pathname: '/login-page' })
      }
    }

  }
  return (
    <>
      <main >
        <section className="section section-shaped section-lg">
          <Container className="">
            <div className='login3-container'>
              <div className="login3-wrapper">
                <h1>Login</h1>
                <form className="login3_form">
                  <div className="login3_txt_field">
                    <input type="text" required
                      values={modelData.username}
                      onChange={(e) => {

                        if (!isNaN(e.target.value)) {
                          if (e.target.value.trim().length == 8) {
                            setUsernameVal(false)
                          } else {
                            setUsernameVal(true)
                          }
                          setModelData({ ...modelData, username: e.target.value.trim() })
                        }
                      }}
                    />
                    <span></span>
                    <label>Username</label>
                    {(validate && !modelData.username) ? 
                    <p className="text-left mt-2 text-danger font-weight-bold">This field is required</p>
                    : null}
                    {(usernameVal && modelData.username) ? <p className="text-left mt-2 text-danger font-weight-bold" >Username must be a 8 digit Number</p>: null}
                  </div>
                  <div className="login3_txt_field">
                    <input type="password" required
                      values={modelData.password}
                      onChange={(e) =>
                        setModelData({ ...modelData, password: e.target.value.trim() })
                      }
                    />
                    <span></span>
                    <label>Password</label>
                    {(validate && !modelData.password) ? <p className="text-left mt-2 text-danger font-weight-bold">This field is required</p>
                    : null}
                  </div>
                  {/* <div className="login3_pass">Forgot Password?</div> */}
                  <button className="login-btn "onClick={(e) => handleLogin(e)}>Login</button>
                  {/* <div className="signup_link">
                    Not a member? <a href="#">Signup</a>
                  </div> */}
                </form>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </>
  );
}


export default Login;

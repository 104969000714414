import React, { useEffect, useState } from "react";

// core components
import DemoNavbar from "components/DemoNavbar";
import HeroSection from "components/HeroSection";
import OnTopBtn from "components/OnTopBtn";
import MainContent from "./TeamDashboard";
import SimpleFooter from "components/SimpleFooter";
function TeamSection() {

  const [onTop, setOnTop] = useState(false)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
    setOnTop(false)
  }, [onTop])

  return (
    <>
      <DemoNavbar />
      <HeroSection />
      <MainContent/>
      <OnTopBtn setOnTop={setOnTop} />
      <SimpleFooter/>
    </>
  );
}

export default TeamSection;

const COMPANY_NAME = "Emphasise Tech"
export const default_img = "https://ik.imagekit.io/emphasisetech/blogs/blog_images/web-development.webp?updatedAt=1680070078758"

export const CONSTANTS = {
    navbar:{
        companyName:COMPANY_NAME,
        nav:{
            navLink1:"Home",
            navLink2:"About Us",
            navLink3:{
                title:"Service",
                links:{
                    service1:"Web & Graphic designing",
                    service2:"Web development",
                    service3:"Mobile App development"
                }
            },
            team:"Team",
            navLink4:"Contact Us",
            navLink5:"Blogs"
        }
    },
    homepage: {
        heroSection: {
            title: COMPANY_NAME,
            line1: "Its time to supercharge your business with results-driven strategy & design.",
            line2: "We Offer High-Tech Solutions to Enterprises, Startups, and Small Businesses."
        },
        main: {
            line1: "RESULTS YOU CAN BELIEVE IN",
            line2: "How does 5x revenue sound to you?",
            line3: "What about a 250 percent increase?",
            line4: "We made it feasible by obsessing over optimization and developing custom design technologies.",
            line5: "WE DO DESIGN, CODE & DEVELOP",
            line6: `${COMPANY_NAME} is the best web development company in India. We are specialized in web designing, software development & custom web application development. ${COMPANY_NAME} believes that good online branding starts with a great website because a website represents your business.`,
            line7: "Our team focuses always on quality work for web development or custom software development. We are a team of professionals designers & development."
        },
        contentBar: {
            line1: "Ready to start a project?",
            line2: "It's not difficult to get started. We start right into the project, keep lines of communication open throughout the engagement, and deliver a speedy turnaround.",
            button:"CONTACT US"
        },
    },
    team:{
        heroSection: {
            line1: "Team Section ",
            line2: "Grow your business online by awesomely designed mobile apps that fits all types.",
        },
    },
    aboutUsPage: {
        heroSection: {
            title: "About Us",
            line1: "We design and develop web and mobile applications for our clients worldwide."
        },
        main: {
            line1: "MISSION",
            line2: "Our mission is to restore the dignity and quality of life for people affected by conflict by eliminating barriers to success through innovative community-driven aid.",
            line3: "Building software for world changers",
            line4: `${COMPANY_NAME} connects businesses with clients and customers. We also listen to our clients to comprehend their goals and business. We consider ourselves your marketing partner that will promote your company brand and name in everything we do.${COMPANY_NAME} is the best web development company in India. We are specialized in web designing, software development & custom web application development. ${COMPANY_NAME} believes that good online branding starts with a great website because a website represents your business. So, before marketing and branding, we concentrate firstly on web development, software development & custom web application development. We are the top ecommerce development agency in India that are developing informative, attractive & great websites according to your choices.`,
        },
        contentBar: {
            title: COMPANY_NAME,
            line1: "WE WORK HARD AND ENTERTAIN OURSELVES",
            line2: "We are a team that loves to collaborate, support each other and push ourselves beyond our comfort zone. And we can't do it without having fun along the way.",
        },
    }, 
    servicePage: {  
        heroSection: {
            category1:{
                title:"Web Graphic Designing",
                line1:"We provide best Web & Graphic Designing solutions here.."
            },
            category2:{
                title:"Web Development",
                line1:"We provide best Web Development solutions here.."
            },
            category3:{
                title:"Mobile App Development",
                line1:"We provide best Mobile App Development solutions here.."
            }
        },
        main: {
            category1:{
                title:"Why Web Designing Services matters?",
                line1:`User interface (UI), user experience (UX), and responsiveness of your web design need to be top-notch in order to make your leads stay and convert. So, from the time that your user enters your website, till the time that they finish their transaction or action, they need to be hooked to the design. One obstacle and they will just bounce. ${COMPANY_NAME} makes sure that your website is interesting, smooth, and technically sound. We make it good enough to lure your audience to visit the website, keep them hooked, and make it easy for them to finish an action. As an experienced UX design company, we pay close attention to the UI/UX design of the website and make it responsive for the comfort of every user.`
            },
            category2:{
                title:"Why Web Development matters?",
                line1:`We are fans of the modular approach to website development. With us, every element of your solution will be a unique, independent module that can be updated or replaced without affecting the entirety of the application. ${COMPANY_NAME} provides the best website development services, custom web development services.`
            },
            category3:{
                title:"Why Mobile App Development Services matters?",
                line1:`These days, portable applications assume an indispensable part in growing a business by permitting organizations to give admittance to their administrations at a solitary touch. You can get notices, tagging, room booking, item arranges, interface with individuals, offer different types of assistance, sell and buy items by utilizing these mobile applications. Get your own business mobile app developed, Cost friendly mobile app development services for your benefit at ${COMPANY_NAME}.`
            },
            brief:{
                title:"Our Development process",
                line1:"Our comprehensive logo design strategy ensures a perfectly crafted logo for your business."
            },
        },
        process:{
            step1:{
                tag:"First Step",
                line1:"Design Brief",
                line2:"The first step in the creative portion of the logo design process is called the design brief. This is when the designer discusses the project at length with the client. Understanding the company’s goals for the logo is critical.The designer should gather as much information from the client as possible. It is important to learn where the logo will be used, on what scale and in what capacity."
            },
            step2:{
                tag:"Second Step",
                line1:"Prototype",
                line2:"Once a sufficient amount of general information has been gathered, the designer must delve deeper into the Prototype stage. In Prototype stage, you can experiment with logo colors as well as typography.Once you have a solid draft,then go the extra mile to create a presentation to showcase our logo"
            },
            step3:{
                tag:"Third Step",
                line1:"Test",
                line2:"Once a prototype stage is done then our tester check the logo design according to client requirements. If client satisfy which you have developed then go deliver process"
            },
            step4:{
                tag:"Last Step",
                line1:"Delivery",
                line2:"After the final decision was made, it’s time to work on the deliverables.You should determine what design files your client needs at the start of the process (in case they have any special requirements).",
            }
            
        },
        contentBar: {
            title: COMPANY_NAME,
            line1: "WE WORK HARD AND ENTERTAIN OURSELVES",
            line2: "We are a team that loves to collaborate, support each other and push ourselves beyond our comfort zone. And we can't do it without having fun along the way.",
        },
    }, 
    contactPage: {
        heroSection: {
            title: "Contact Us",
            line1: "Get in touch with us"
        },
        main: {
            line1: "Have some unanswered questions ?",
            line2: "Let's Create Progress Together",
            line3: "Want to learn more about the all-in-one business automation platforms? Submit your queries and we will get back to you as soon as possible.",
        },
        contentBar: {
            form:{
                title:"Get in touch",
                field1:"Name",
                field2:"Email",
                field3:"Enter your message"
            },
            phone:{
                title:"Phone",
                line1:"You can reach us in person Monday through Friday between 9:30 am and 6:30 pm",
                line2:"0161-2990534"
            },
            email:{
                title:"Email",
                line1:"You will hear back from our support team within 48 hours during standard business hours.",
                line2:"emphasisetech@gmail.com"
            }
        },
    },
    blogSection:{
        blogList:{
            title:"BlOGS",
            button:"ADD BLOG",
            line1:"You haven't added any blogs yet."
        },
        blog:{
            button:"Read More"
        },
        addBlog:{
            title:"What's on your mind ?",
            field1:{
                title:"Title",
                placeholder:"Enter here"
            },
            field2:{
                title:"Content  ",
                placeholder:"Enter here"
            },
            field3:{
                title:"Short Description",
                placeholder:"Enter here"
            },
            field4:{
                title:"Category",
                dropdown:{
                    title:"Select Category",
                    options:{
                        title1:"Programming",
                        title2:"Web designing",
                        title3:"Bollywood",
                        title4:"Fiction"
                    }
                }
            },
            button:{
                createBlog:"CREATE BLOG",
                resetBlog:"RESET BLOG",
                updateBlog:"UPDATE BLOG"
            }

        }
    },
    footer: {
        company: {
            title: COMPANY_NAME,
        },
        category1: {
            title: "About Us"
        },
        category2: {
            title: "Services",
            data: {
                link1: "Web & Graphic Designing",
                link2: "Web Development",
                link3: "Mobile App Development"
            }
        },
        category3: {
            title: "Blogs"
        },
    }
}
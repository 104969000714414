import React from 'react'
import DepartmentTable from './DepartmentTable'
import BasicLayout from 'components/BasicLayout'

function DepartmentSection() {

  const SampleComponent = BasicLayout(DepartmentTable)
  return (
    <SampleComponent />
  )
}

export default DepartmentSection
import React from "react";
import { CONSTANTS } from "constant/Content";
import { Container, Row, Col } from "reactstrap";
import img1 from "../assets/img/about_1.avif";
import img2 from "../assets/img/about2.avif";
import { Link } from "react-router-dom";

function AboutIntro({ pageName }) {
  const headingStyles = {
    color: "black",
    textTransform: "uppercase",
  };

  return (
    <>
      <Container
        className="eachSectionMarginTop about-intro-section"
        data-aos="slide-right"
        data-aos-duration="1500"
        data-aos-offset="200"
      >
        <Row className="justify-content-center">
          <Col className="col-lg-5 mr-auto mb-5 p-5  p-md-0  col-12">
            <span class="d-block text-uppercase lead  p-0 m-0">Who We Are</span>
            <span class="divider my-4"></span>
            <h2 class="mb-4 font-weight-300" style={headingStyles}>
              We Are <strong className="font-weight-700">Design Agency</strong>{" "}
              That Bring Your Ideas Alive
            </h2>
            <p className="mb-4">
              {CONSTANTS.homepage.main.line6}
              <br></br>
              <br></br>
              {CONSTANTS.homepage.main.line7}
            </p>
            <Link to="/about-page">
              <button className="themeBtn2 py-2 px-4">
                {CONSTANTS.blogSection.blog.button}
              </button>
            </Link>
          </Col>
          <Col className="col-lg-6 p-5 p-md-0  d-flex justify-content-center">
            <div className="feature-div">
              <img src={img1} alt="Image" />
            </div>
          </Col>
        </Row>
      </Container>
      {pageName === "about-page" ? (
        <Container
          className="eachSectionMarginTop pl-0"
          data-aos="slide-left"
          data-aos-duration="1500"
          data-aos-offset="200"
        >
          <Row className="justify-content-center">
            <Col className="col-lg-6 p-lg-0 col-12">
              <div className="feature-div pl-0 d-flex justify-content-center">
                <img
                  src={img2}
                  alt="Image"
                  style={{ height: "100%", width: "80%" }}
                />
              </div>
            </Col>
            <Col className="col-lg-5 mr-auto mb-5 p-lg-0 p-5 col-12">
              <span class="d-block text-uppercase lead  p-0 m-0">We Do</span>
              <span class="divider my-4"></span>
              <h2 class="mb-4 font-weight-300" style={headingStyles}>
                {CONSTANTS.aboutUsPage.main.line3}{" "}
                <strong className="font-weight-700"></strong>{" "}
              </h2>
              <p className="mb-4">{CONSTANTS.aboutUsPage.main.line4}</p>
              {/* <button className='themeBtn2 py-2 px-4'>{CONSTANTS.blogSection.blog.button}</button> */}
            </Col>
          </Row>
        </Container>
      ) : null}
    </>
  );
}

export default AboutIntro;

import React, { useEffect, useState } from "react";
import { Container, Table, Button } from "reactstrap";
import {
  GetUserList,
  EnableUser,
  BlockUser,
  UnblockUser,
  DisableUser,
} from "services/api/user";
import SignupModel from "../../../components/Modal";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useLocation } from "react-router-dom";

function UserTable() {
  const [userListData, setUserListData] = useState();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  // const [skipNo, setSkipNo] = useState(0)

  // pagination
  const [count, setCount] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);

  const location = useLocation();
  const [section, setSection] = useState(location.pathname.split("/")[1]);

  useEffect(() => {
    async function fetchData() {
      let res = await GetUserList(count, pageNumber);
      setUserListData(res?.data?.data);
    }
    fetchData();
  }, [refresh]);

  const handleAddUser = () => {
    setShow(true);
  };
  const handleEnableUser = async (id) => {
    let res = await EnableUser(id);
    setRefresh(!refresh);
  };
  const handleDisableUser = async (id) => {
    let res = await DisableUser(id);
    setRefresh(!refresh);
  };
  const handleBlockUser = async (id) => {
    let res = await BlockUser(id);
    setRefresh(!refresh);
  };
  const handleUnblockUser = async (id) => {
    let res = await UnblockUser(id);
    setRefresh(!refresh);
  };
  const hanldeIncrement = async (el, i) => {
    setPageNumber(pageNumber + 1);
    setRefresh(!refresh);
  };
  const handleDecrement = async (el, i) => {
    setPageNumber(pageNumber - 1);
    setRefresh(!refresh);
  };

  const rowStyles = {
    padding: "20px 0",
  };

  return (
    <>
      {show ? (
        <SignupModel
          section={section}
          refresh={refresh}
          setRefresh={setRefresh}
          userListData={userListData}
          setUserListData={setUserListData}
          setShow={setShow}
          show={show}
        />
      ) : null}
      <div className="mt-5 mt-md-0" style={{ backgroundColor: "white" }}>
        <Container className="mb-5">
          <h4>User Section</h4>
        </Container>
        <Container className="mb-3 sideByside">
          <select
            value={count}
            onChange={(e) => {
              setCount(e.target.value);
              setPageNumber(1);
              setRefresh(!refresh);
            }}
            style={{ width: "100px" }}
          >
            {/* <option hidden selected>Select page no.</option> */}
            <option value="5" selected>
              5
            </option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
          <Button color="primary" onClick={handleAddUser}>
            Add User
          </Button>
        </Container>
        <Container className="shape-container d-flex align-items-center  ">
          {userListData?.length ? (
            <Table bordered size="sm" className="user-table ">
              <thead>
                <tr className="user-table">
                  <th>S.no</th>
                  <th>Name</th>
                  <th>Username</th>
                  <th>Department</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th className="text-center">Actions </th>
                </tr>
              </thead>
              <tbody>
                <tr></tr>
                {userListData?.map((val, i) => {
                  return (
                    <>
                      <tr key={i} className="user-table-col">
                        <td style={rowStyles}>{i + 1}</td>
                        <td style={rowStyles}>{val.name}</td>
                        <td style={rowStyles}>{val.username}</td>
                        <td style={rowStyles}>{val.department}</td>
                        <td style={rowStyles}>{val.eRole}</td>
                        <td style={rowStyles}>{val.status}</td>
                        <td style={rowStyles}>
                          <div>
                            {val.status == 0 ? (
                              <div>
                                <Button
                                  color="success"
                                  onClick={() => handleEnableUser(val._id)}
                                >
                                  Enable
                                </Button>
                                <Button
                                  color="dark"
                                  onClick={() => handleBlockUser(val._id)}
                                >
                                  Block
                                </Button>
                              </div>
                            ) : val.status == 1 ? (
                              <div>
                                <Button
                                  color="danger"
                                  onClick={() => handleDisableUser(val._id)}
                                >
                                  Disable
                                </Button>
                                <Button
                                  color="dark"
                                  onClick={() => handleBlockUser(val._id)}
                                >
                                  Block
                                </Button>
                              </div>
                            ) : (
                              <div>
                                <Button
                                  disabled
                                  color="danger"
                                  onClick={() => handleDisableUser(val._id)}
                                >
                                  Disable
                                </Button>
                                <Button
                                  color="success"
                                  onClick={() => handleUnblockUser(val._id)}
                                >
                                  UnBlock
                                </Button>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <div className="text-center w-100">
              <p>No Results Found</p>
            </div>
          )}
        </Container>
        <div className="mt-3">
          <Container>
            <Button
              color="primary"
              disabled={pageNumber == 1 ? true : false}
              onClick={() => handleDecrement()}
            >
              <FaChevronLeft />
            </Button>
            <span className="whiteBold mr-2">{pageNumber}</span>
            <Button
              color="primary"
              disabled={userListData?.length < count ? true : false}
              onClick={() => hanldeIncrement()}
            >
              <FaChevronRight />
            </Button>
          </Container>
        </div>
      </div>
    </>
  );
}

export default UserTable;

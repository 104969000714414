import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import * as _ from "lodash"
import ThemeContainer from 'components/ThemeContainer';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { AddCertificate } from 'services/api/user';
import { saveAs } from 'file-saver';
import TextError from 'components/TextError';
import TContainer from 'components/Toast';
import { toastNotification } from 'components/Toast';

import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

const Test = () => {

  const [validate, setValidate] = useState(false);
  const [startDate, setStartDate] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [selectedDuration, setSelectDuration] = useState();

  const [fileName, setFileName] = useState()
  const [isDisable, setIsDisable] = useState(true)

  const [modelData, setModelData] = useState({
    name: "",
    course: "",
    start_date: "",
    end_date: "",
    category: ""
  })

  const [options, setOptions] = useState([
    {
      value: "PARTICPATION",
      label: "Particpation"
    },
    {
      value: "APPRECIATION",
      label: "Appreciation"
    },
    {
      value: "COMPLETION",
      label: "Completion"
    },
  ])

  const [durationList, setDurationList] = useState([
    {
      value: 45,
      label: "45 Days"
    },
    {
      value: 90,
      label: "90 Days"
    },

  ])

  const handleSubmit = async () => {

    setValidate(true)
    modelData.start_date = moment(startDate).format('L');
    setFileName(modelData.name)
    // Date logic
    let date = startDate
    date.setDate(date.getDate() + selectedDuration.value)
    modelData.end_date = moment(date).format('L')

    if (moment(date).format('dddd') == "Sunday") {
      let newDate = date
      newDate.setDate(newDate.getDate() + 1)
      modelData.end_date = moment(newDate).format('L')
    }

    modelData.category = selectedOption.value
    const notEmpty = await _.values(modelData).every(_.negate(_.isEmpty));

    if (notEmpty == true) {
      let res = await AddCertificate(modelData)
      if (res.status == 200) {
        toastNotification("success","Form Submited succesfully")

        setStartDate(null)
        setValidate(false)
        setModelData({
          name: "",
          course: "",
        })
        setSelectedOption(null)
        setSelectDuration(null)
        setIsDisable(false)
      }
    } else {
      alert("Please fill all form fields")
      setValidate(true)
    }
  }

  const hanldeDownloadPdf = () => {
    saveAs(`http://localhost:8080/${fileName}.pdf`, "Download.pdf")
    setIsDisable(true)
  }

  return (
    <>
      <Container >
        <Row className="justify-content-center ">
          <Col lg="12 p-0">
            <Card className="bg-secondary shadow border-0 " >
              <CardBody>
                <div className="text-center mb-4">
                  <h4 className='text-primary font-weight-bold'>ENTER DETAILS</h4>
                </div>
                <Form role="form">
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Name" type="text"
                        value={modelData.name}
                        onChange={(e) => setModelData({ ...modelData, name: e.target.value })}
                      />
                    </InputGroup>
                    {(validate && !modelData.name) ? <TextError children={'This field is required'} /> : null}
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Course" type="text"
                        value={modelData.course}
                        onChange={(e) => {
                          setModelData({ ...modelData, course: e.target.value })
                        }
                        }
                      />
                    </InputGroup>
                    {(validate && !modelData.course) ? <TextError children={'This field is required'} /> : null}
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <BsFillCalendarDateFill />
                        </InputGroupText>
                        <DatePicker className='date-field-style ' placeholderText='Start Date' selected={startDate} onChange={(date) => setStartDate(new Date(date))} />
                      </InputGroupAddon>
                    </InputGroup>
                    {(validate && !modelData.start_date) ? <TextError children={'This field is required'} /> : null}
                  </FormGroup>
                  <FormGroup>
                    <Select
                      value={selectedDuration}
                      placeholder="Select Duration..."
                      onChange={setSelectDuration}
                      options={durationList}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Select
                      value={selectedOption}
                      placeholder="Select Category..."
                      onChange={setSelectedOption}
                      options={options}
                    />
                    {(validate && !selectedOption) ? <TextError children={'This field is required'} /> : null}
                  </FormGroup>
                  <div className="text-right">
                    {/* <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button> */}
                    <Button
                      variant={isDisable ? "secondary" : "primary"}
                      type="button"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                    <Button
                      disabled={isDisable ? true : false}
                      variant={isDisable ? "primary" : "secondary"} onClick={() => hanldeDownloadPdf()}>
                      {isDisable ? "Download" : "Download(1)"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <TContainer/>
    </>
  )
}


function EnterDetails() {
  const comp = <Test />
  return (
    <>
      <ThemeContainer mainComponent={comp} />
    </>
  )
}

export default EnterDetails
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";
import { CONSTANTS } from "constant/Content";
import Service1 from "../assets/img/service2.avif"
import Service2 from "../assets/img/service4.avif"
import Service3 from "../assets/img/service3.avif"


function MainContent() {
  const location = useLocation();
  const serviceTitle = new URLSearchParams(location.search).get("service")
  const section = location.pathname.split("/")[1]
  const [serviceName, setServiceName] = useState(section == "about-page" ? section : serviceTitle)

  const pageTitle = location.pathname.split("/")[1]
  const [pageName, setPageName] = useState(pageTitle)


  useEffect(() => {
    setServiceName(serviceTitle)
  }, [serviceTitle])

  useEffect(() => {
    setPageName(pageTitle)
  }, [pageTitle])

  const headingStyles = {
    color: "black",
    textTransform: "uppercase"
  }

  return (
    <>
      {
        pageName == "about-page" ?
          <div className="position-relative eachSectionMarginTop">
            <Container className="shape-container d-flex align-items-center ">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center "
                  data-aos="fade-up"
                  data-aos-offset="150"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                >
                  <Col lg="12">
                    <div className="text-center">
                      <h1 className="textBold mb-3">{CONSTANTS.aboutUsPage.main.line1}</h1>
                      <p className="lead">
                        {CONSTANTS.aboutUsPage.main.line2}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          :
          <Container className="eachSectionMarginTop " data-aos="slide-left" data-aos-duration="1500" data-aos-offset="200">
            <Row className="justify-content-center">
              <Col className="col-lg-5 mr-auto mb-5 p-0 " >
                <span class="d-block text-uppercase lead  p-0 m-0">Development</span>
                <span class="divider my-4"></span>
                <h2 class="mb-4 font-weight-300" style={headingStyles}>
                  why <strong className="font-weight-700"> {(serviceName == "webGraphicDesigning") ? "web designing": (serviceName == "webDevelopment") ? "Web development " : (serviceName == "moblieApp") ? "mobile app" : "Service Section"} </strong> services matters ? </h2>
                {(serviceName == "webGraphicDesigning") ?
                  <p className="mb-4">
                    {CONSTANTS.servicePage.main.category1.line1}
                  </p>
                  : (serviceName == "webDevelopment") ?
                    <p className="mb-4">
                      {CONSTANTS.servicePage.main.category2.line1}
                    </p>
                    : (serviceName == "moblieApp") ? <p className="mb-4">
                      {CONSTANTS.servicePage.main.category3.line1}
                    </p> : "Service Section"}
              </Col>
              <Col className="col-lg-6 d-flex justify-content-center  ">
                <div className="feature-div" >
                  <img src={serviceName == "webGraphicDesigning"? Service1 : (serviceName == "webDevelopment") ? Service2 : (serviceName == "moblieApp") ? Service3 : ""} alt="Image" className="img-fluid" style={{height:"85%"}} />
                </div>
              </Col>
            </Row>
          </Container>
      }


    </>
  );
}

export default MainContent;

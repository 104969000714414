import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { FaPhoneAlt } from "react-icons/fa";
import { CONSTANTS } from "constant/Content";
import bannerImg from "../assets/img/banner4.avif";

function ContentBar() {
  const location = useLocation();
  const pageTitle = location.pathname.split("/")[1];
  const [pageName, setPageName] = useState(pageTitle);

  useEffect(() => {
    setPageName(pageTitle);
  }, [pageName]);

  const heroStyles = {
    backgroundImage: `url(${bannerImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
    position: "relative",
  };
  const overLayStyles = {
    position: "absolute",
    top: "0",
    height: "100%",
    width: "100%",
    background: "linear-gradient(to bottom,rgba(0,0,0,0.8),rgba(0,0,0,0.2)) ",
  };

  const iconStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // Add any other background-related styles as needed
  };
  return (
    <section
      style={heroStyles}
      className="section  section-shaped eachSectionMarginTop content-bar-section"
    >
      <div style={overLayStyles}></div>
      <Container className="py-md">
        <Row className="row-grid justify-content-center align-items-center">
          {pageName == "about-page" ? (
            <Col
              lg="10"
              className="text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <h1 className="whiteBold letterSpacing mb-3 text-uppercase">
                {CONSTANTS.aboutUsPage.heroSection.title}
              </h1>
              <p
                className="py-2 text-uppercase font-weight-bold"
                style={{ backgroundColor: "white" }}
              >
                {CONSTANTS.aboutUsPage.contentBar.line1}
              </p>
              <p className="lead text-white">
                {CONSTANTS.aboutUsPage.contentBar.line2}
              </p>
            </Col>
          ) : pageName == "" ? (
            <Col
              lg="12"
              className="text-center"
              data-aos="fade-up"
              data-aos-offset="150"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <h1 className="whiteBold">
                {CONSTANTS.homepage.contentBar.line1}
              </h1>
              <p className="lead text-white">
                {CONSTANTS.homepage.contentBar.line2}
              </p>
              <div className="btn-wrapper" style={iconStyles}>
                <div className="text-white custom-icon2">
                  <FaPhoneAlt />
                </div>
                <Button
                  to="/contact-page"
                  tag={Link}
                  className="custom-btn signup-btn px-2 ml-3"
                >
                  {CONSTANTS.homepage.contentBar.button}
                </Button>
              </div>
            </Col>
          ) : null}
        </Row>
      </Container>
    </section>
  );
}

export default ContentBar;

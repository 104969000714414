import api from '../index';
// import * as _helper from '../helper';
import * as URL from '../url';

export const AddQuery = async (body) => {
    try {
        const response = await api.post(URL.QUERY, body);
        if(!response?.data.success){
            // _helper.sendResponse('error', response.data.message)
        }else{
            console.log("response AddQuery** ", response)
            return response
        }
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}


export const GetQuery = async () => {
    try {
        const response = await api.get(URL.QUERY);
            console.log("response GetQuery** ", response)
            return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}

export const GetQueryById = async (id) => {
    try {
        const response = await api.get(URL.QUERY + "/" + id);
            console.log("response GetQuery** ", response)
            return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}
export const AcceptQueryById = async (id) => {
    try {
        const response = await api.patch(URL.QUERY + "/" + id);
            console.log("response AcceptQueryById** ", response)
            return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}

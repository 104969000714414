
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

import { CONSTANTS } from "constant/Content";

function SimpleFooter({setOnTop}) {
  return (
    <>
      <footer className="footer eachSectionMarginTop">
        <Container >
          <Row className="align-items-start justify-content-md-between">
            <Col className="col-12 col-md-6 mb-3">
              <div className="copyright">
                © 2020 
                {/* © {new Date().getFullYear()}{" "} */}
                <Link to="#"
                className="text-white font-weight-bold"
                  onClick={()=>setOnTop(true)}
                >
                <span> </span>{CONSTANTS.footer.company.title}
                </Link>
              </div>
            </Col>
            <Col className="col-12 col-md-2 d-flex justify-content-md-end mb-3 "><Link to="/about-page"><p className="text-white font-weight-bold">{CONSTANTS.footer.category1.title}</p></Link></Col>
            <Col className="col-12 col-md-3 "><p className="ml-md-4 text-white font-weight-bold">{CONSTANTS.footer.category2.title}</p>
            <ul style={{fontSize:"15px",listStyle:'none',paddingLeft:'22px'}}>
              <Link className="text-white " to="/service-page?service=webGraphicDesigning"><li>{CONSTANTS.footer.category2.data.link1}</li></Link>
              <Link className="text-white " to="/service-page?service=webDevelopment"><li>{CONSTANTS.footer.category2.data.link2}</li></Link>
              <Link className="text-white " to="/service-page?service=moblieApp"><li>{CONSTANTS.footer.category2.data.link3}</li></Link>
            </ul>
            </Col>
            <Col className="col-12 col-md-1 d-flex justify-content-md-end mb-3  ">
              <Link className="text-white font-weight-bold" to="/blog-list"><p className="text-white font-weight-bold">{CONSTANTS.footer.category3.title}</p></Link></Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default SimpleFooter;

import React  from 'react'
import Sidebar from './MainLayout'

const BasicLayout = (Component) => {
  const component = <Component/>
  return () => (
      <Sidebar compArr={component} />
  )
}

export default BasicLayout
import React from 'react'
import EnterDetails from './EnterDetails'
import BasicLayout from 'components/BasicLayout'

function CertificateSection() {

  const SampleComponent = BasicLayout(EnterDetails)
  return (
    <SampleComponent />
  )
}

export default CertificateSection
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import { GetBlogById } from "services/api/blogs";
import OnTopBtn from "components/OnTopBtn";
import { default_img } from "constant/Content";
import Loder from "components/Loader";
import blogUser from "../../../assets/img/blog-user.jpg";
import { UserById } from "services/api/user";

function Blog() {
  const location = useLocation();
  let blogId = new URLSearchParams(location.search).get("id");

  const [blogData, setBlogData] = useState();
  const [onTop, setOnTop] = useState(false);
  const [user, setUser] = useState("");
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    async function fetchData() {
      let res = await GetBlogById(blogId);
      if (res?.data?.success) {
        setBlogData(res?.data?.data);
        setIsLoader(false);
      } else {
        setBlogData("");
        setIsLoader(true);
      }

      let data = await UserById(blogData?.createdBy);
      setUser(data?.data?.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
    setOnTop(false);
  }, [onTop]);

  return (
    <>
      <OnTopBtn setOnTop={setOnTop} />
      {isLoader ? (
        <Loder loaded={isLoader} />
      ) : (
        <Container>
          <div className="p-2 p-md-5 mt-5">
            <div>
              <img
                className="mb-5"
                src={blogData?.blog_img ? blogData.blog_img : default_img}
                style={{
                  height: "300px",
                  width: "100%",
                  // objectFit:"cover"
                }}
              />
              <div className="blog-user mb-5">
                <img src={blogUser} />
                <div className="info font-weight-bold pt-3">
                  <span>{user?.name}</span>
                  <p className="font-weight-bold" style={{ fontSize: "12px" }}>
                    {blogData?.createdAt}
                  </p>
                </div>
              </div>
              <h1 className="textBold text-capitalize m-0">
                {blogData?.title}
              </h1>
              {/* <div className='d-flex'>
                        <Badge pill className="bg-danger mb-3 text-white mr-2 py-2 px-3">
                          {blogData?.category}
                        </Badge>
                        <small>{blogData?.createdAt}</small>
                      </div> */}
              <div dangerouslySetInnerHTML={{ __html: blogData?.content }} />
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

export default Blog;

import api from '../index';
// import * as _helper from '../helper';
import * as URL from '../url';

export const CreateBlog= async (body) => {
    try {
        const response = await api.post(URL.BLOGS, body);
        if(!response?.data?.success){
            // _helper.sendResponse('error', response.data.message)
        }else{
            console.log("response AddQuery** ", response)
            return response
        }
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}

export const GetBlogs = async () => {
    try {
        const response = await api.get(URL.BLOGS);
        if(!response?.data.success){
            // _helper.sendResponse('error', response.data.message)
        }else{
            console.log("response GetBlogs** ", response)
            return response
        }
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}

export const GetCategoryList = async () => {
    try {
        const response = await api.get(URL.CATEGORY);
        if(!response?.data.success){
            // _helper.sendResponse('error', response.data.message)
        }else{
            console.log("response GetCategoryList** ", response)
            return response
        }
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}

export const GetBlogsByEmployee = async () => {
    try {
        const response = await api.get(URL.BLOGS_BY_EMPLOYEE);
        if(!response?.data.success){
            // _helper.sendResponse('error', response.data.message)
        }else{
            console.log("response GetBlogs** ", response)
            return response
        }
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}

export const GetBlogById = async (id) => {
    try {
        const response = await api.get(URL.BLOG_ID + id);
        if(!response?.data.success){
            // _helper.sendResponse('error', response.data.message)
        }else{
            console.log("response GetBlogById** ", response)
            return response
        }
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}

export const UpdateBlog = async (id,body) => {
    try {
        const response = await api.patch(URL.BLOG_ID + id,body);
        if(response?.data.success){
            console.log("response UpdateBlog* ", response)
            return response

        }else{
            console.log("response UpdateBlog** ", response)
            return response
        }
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}

export const DeleteBlog = async (id) => {
    try {
        const response = await api.delete(URL.BLOG_ID + id);
        if(response?.data.success){
            console.log("response DeleteBlog* ", response)
            return response

        }else{
            console.log("response DeleteBlog** ", response)
            return response
        }
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}


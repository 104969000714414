import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Process from "./ServiceProcess";
import { CONSTANTS } from "constant/Content";

function PlanTextBox() {
  const location = useLocation();
  const pageTitle = (location.pathname.split("/")[1])
  const serviceTitle = new URLSearchParams(location.search).get("service")
  const [pageName, setPageName] = useState(pageTitle)
  const [service, setService] = useState(serviceTitle)

  useEffect(() => {
    setPageName(pageTitle)
    setService(serviceTitle)
  }, [pageName, service])

  return (
    <>
      <Container className="shape-container d-flex align-items-center 
      eachSectionMarginTop" 
      data-aos={pageName !== "service-page" ? "slide-left":"fade-up"} data-aos-duration="1500">
        <div className="col px-0">
          <Row className="align-items-center justify-content-center">
            {
              pageName == "" ?
                <Col className="text-center" lg="12">
                  <p className="textBold mb-3 letterSpacing ">{CONSTANTS.homepage.main.line1}</p>
                  <h1 className="textBold">{CONSTANTS.homepage.main.line2}</h1>
                  <h1 className="textBold mb-3">{CONSTANTS.homepage.main.line3}</h1>
                  <p className="lead">
                  {CONSTANTS.homepage.main.line4}
                  </p>
                </Col>
                :
                pageName == "service-page" && (service == "webGraphicDesigning" || service == "webDevelopment" || service == "moblieApp") ?
                  <Col className="text-center" lg="12">
                    <div>
                      <h1 className="textBold">{CONSTANTS.servicePage.main.brief.title}</h1>
                      <p className="lead">
                      {CONSTANTS.servicePage.main.brief.line1}
                      </p>
                      <Process />
                    </div>
                  </Col>
                  : null
            }
          </Row>
        </div>
      </Container>
    </>
  );
}

export default PlanTextBox;

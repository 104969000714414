export const SIGN_IN= '/auth/login';
export const SIGN_UP= '/auth/signup';

export const USER_BY_ID = '/user/'
export const USER_LIST = '/user/list'
export const EMPLOYEE_LIST = '/user/employeeList'
export const ENABLE_USER = '/user/enable/'
export const DISABLE_USER = '/user/disable/'
export const BLOCK_USER = '/user/block/'
export const UNBLOCK_USER = '/user/unblock/'

export const CERTIFICATE = '/certificate'


export const QUERY = '/queries';

export const DEPARTMENT = '/department';
export const ENABLE = '/department/enable/';
export const DISABLE = '/department/disable/';
export const DELETE = '/department/delete/';



export const BLOGS = '/blogs';
export const BLOGS_BY_EMPLOYEE = '/blogs/by-employee';
export const BLOG_ID = '/blogs/';

export const CATEGORY = '/blog-category';

export const TXN_HISTORY = "/txn/history/"

export const CONTACT_NUMBER = '/contact-number';
export const ENABLE_CONTACT_NUMBER = '/contact-number/enable/';
export const FAVOURITE_CONTACT_NUMBER = '/contact-number/favourite/';
export const DISABLE_CONTACT_NUMBER = '/contact-number/';
export const DELETE_CONTACT = '/contact-number/delete/';

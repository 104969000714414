import React, { useEffect, useState } from "react";
import DemoNavbar from "components/DemoNavbar.js";
import PlanTextBox from "../../../components/PlanTextBox.js";
import HeroSection from "./HeroSection.js";
import ContentBar from "components/ContentBar.js";
import OnTopBtn from "components/OnTopBtn.js";
import SimpleFooter from "components/SimpleFooter.js";
import MainFeature from "components/MainFeature.js";
import AboutIntro from "../../../components/AboutIntro.js";
import { useLocation } from "react-router-dom";
import ServiceIntro from "components/ServiceIntro.js";
import Counter from "components/Counter.js";
import Testimonial from "components/Testimonial.js";

function Homepage() {
  sessionStorage.clear();
  const location = useLocation();
  const [onTop, setOnTop] = useState(false);
  const pageTitle = location.pathname.split("/")[1];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
    setOnTop(false);
  }, [onTop]);

  return (
    <>
      <DemoNavbar />
      <HeroSection />
      <PlanTextBox />
      <AboutIntro />
      <MainFeature />
      {/* <BlogCarousel/> */}
      <ContentBar />
      <ServiceIntro />
      {/* <ColorCarousel /> */}
      <OnTopBtn setOnTop={setOnTop} />
      <Testimonial />
      <Counter />
      <SimpleFooter />
    </>
  );
}

export default Homepage;

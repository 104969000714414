import React, { useState } from "react";
import { Container, Button } from "reactstrap";
import Login from "../auth/Login";
import { Link } from "react-router-dom";

function HeroSection() {
  const [showLoginPage, setShowLoginPage] = useState(false);

  const handleClose = () => {
    setShowLoginPage(false);
  };
  return (
    <>
      <section className="banner-area">
        <div className="banner-area-overlay start">
          <Container
            className="banner-content"
            data-aos="slide-right"
            data-aos-duration="1500"
          >
            <h1 className="">
              "The future belongs to those who believe in the beauty of their
              dreams." - Eleanor Roosevelt
            </h1>
            <div className="text-center">
              <Link to="/about-page">
                <Button className="custom-btn signup-btn text-white">
                  About Us
                </Button>
              </Link>
              {/* <Button className="custom-btn signup-btn text-white" onClick={() => setShowLoginPage(true)} >Login</Button> */}
            </div>
          </Container>
        </div>
        {showLoginPage ? (
          <div className="login-area-overlay">
            <div className="text-right">
              <h4 className="pr-5 mt-4 closeBtn" onClick={handleClose}>
                X
              </h4>
              <Login />
            </div>
          </div>
        ) : null}
      </section>
    </>
  );
}

export default HeroSection;

import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import TextError from 'components/TextError';
import { SignUp } from 'services/api/auth';
import * as _helper from "../utils/Helper"
import * as _ from 'lodash';
import { DepartmentList, CreateDepartment } from 'services/api/department';
import Select from 'react-select';


import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

const options = []

function SignupModel({ show, setShow, refresh, setRefresh, section }) {

  const [validate, setValidate] = useState(false);
  const [emailVal, setEmailVal] = useState(false)
  const [contactVal, setContactVal] = useState(false)
  const [usernameVal, setUsernameVal] = useState(false)
  const [deparmentList, setDeparmentList] = useState()

  const [selectedOption, setSelectedOption] = useState();

  const [modelData, setModelData] = useState({
    name: "",
    username: "",
    contactNumber: "",
    email: "",
    password: "",
    department: "",
    eRole: ""
  })

  useEffect(() => {
    async function getData() {
      let res = await DepartmentList()
      setDeparmentList(res?.data?.data)

      res?.data?.data.forEach(e => {
        options.push({ value: e._id, label: e.name })
      })

    }
    getData();
  }, [])


  const handleClose = () => setShow(false);

  const handleSubmit = async () => {

    if (section == "user-section") {
      modelData.department = selectedOption?.value

      setValidate(true)
      if (modelData.name !== "" && modelData.username !== "" && modelData.contactNumber !== "" && modelData.email !== "" && modelData.password !== "" && modelData.department !== "") {
        

        let res = await SignUp(modelData)
        console.log(res?.data?.success)
        if (res?.data?.success) {
          alert("User succesfully added")
          setValidate(false)
          setShow(false)
          setRefresh(!refresh)
          return res;
        } else {
          setShow(true)
        }
      }

    } else if (section == "department-section") {
      if (modelData.name !== "") {
        console.log(modelData, "modelData")

        let res = await CreateDepartment(modelData)
        console.log(res?.data?.success)
        if (res?.data?.success) {
          alert("Department succesfully added")
          setValidate(false)
          setShow(false)
          setRefresh(!refresh)
          return res;
        } else {
          setShow(true)
        }
      }
    }

  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Container >
            <Row className="justify-content-center ">
              <Col lg="12 p-0">
                <Card className="bg-secondary shadow border-0 " >
                  <CardBody>
                    <div className="text-center mb-4">
                      <h4 className='text-primary font-weight-bold'>{section == "department-section" ? "ADD DEPARTMENT" : "ADD USER"}</h4>
                    </div>
                    {
                      section == "department-section" ?
                        <Form role="form">
                          <FormGroup>
                            <InputGroup className="input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-hat-3" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="Name" type="text"
                                value={modelData.name}
                                onChange={(e) => setModelData({ ...modelData, name: e.target.value })}
                              />
                            </InputGroup>
                            {(validate && !modelData.name) ? <TextError children={'This field is required'} /> : null}
                          </FormGroup>
                          <div className="text-right">
                            <Button variant="secondary" onClick={handleClose}>
                              Close
                            </Button>
                            <Button
                              className=""
                              color="primary"
                              type="button"
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          </div>
                        </Form>
                        :
                        <Form role="form">
                          <FormGroup>
                            <InputGroup className="input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-hat-3" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="Name" type="text"
                                value={modelData.name}
                                onChange={(e) => setModelData({ ...modelData, name: e.target.value })}
                              />
                            </InputGroup>
                            {(validate && !modelData.name) ? <TextError children={'This field is required'} /> : null}
                          </FormGroup>
                          <FormGroup>
                            <InputGroup className="input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-hat-3" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="Username" type="text"
                                value={modelData.username}
                                onChange={(e) => {
                                  if (!isNaN(e.target.value)) {
                                    if (e.target.value.trim().length == 8) {
                                      setUsernameVal(false)
                                    } else {
                                      setUsernameVal(true)
                                    }
                                    setModelData({ ...modelData, username: e.target.value.trim() })
                                  }
                                }}
                              />
                            </InputGroup>
                            {(validate && !modelData.username) ? <TextError children={'This field is required'} /> : null}
                            {(usernameVal && modelData.username) ? <TextError children={'Username must be a 8 digit Number'} /> : null}
                          </FormGroup>
                          <FormGroup>
                            <InputGroup className="input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-83" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="Contact Number" type="email"
                                value={modelData.contactNumber}
                                onChange={(e) => {
                                  if (!_helper.contactNumberValidation(e.target.value)) {
                                    setContactVal(true);
                                  } else {
                                    setContactVal(false);
                                  }
                                  setModelData({ ...modelData, contactNumber: e.target.value.trim() })
                                }
                                }
                              />
                            </InputGroup>
                            {(validate && !modelData.contactNumber) ? <TextError children={'This field is required'} /> : null}
                            {(contactVal && modelData.contactNumber) ? <TextError children={'Please enter valid contact number'} /> : null}
                          </FormGroup>
                          <FormGroup>
                            <InputGroup className="input-group-alternative mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-83" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="Email" type="email"
                                value={modelData.email}
                                onChange={(e) => {
                                  if (!_helper.emailValidation(e.target.value.trim())) {
                                    setEmailVal(true);
                                  } else {
                                    setEmailVal(false);
                                  }
                                  setModelData({ ...modelData, email: e.target.value.trim() })
                                }}
                              />
                            </InputGroup>
                            {(validate && !modelData.email) ? <TextError children={'This field is required'} /> : null}
                            {emailVal && modelData.email ? <TextError children={'Please enter valid email'} /> : null}
                          </FormGroup>
                          <FormGroup>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-lock-circle-open" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Password"
                                type="password"
                                autoComplete="off"
                                values={modelData.password}
                                onChange={(e) =>
                                  setModelData({ ...modelData, password: e.target.value.trim() })
                                }
                              />
                            </InputGroup>
                            {(validate && !modelData.password) ? <TextError children={'This field is required'} /> : null}
                          </FormGroup>
                          <FormGroup>
                            <Select
                              placeholder="Select Department..."
                              onChange={setSelectedOption}
                              options={options}
                            />
                          </FormGroup>
                          <FormGroup>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-lock-circle-open" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Employee Role"
                                type="text"
                                autoComplete="off"
                                values={modelData.eRole}
                                onChange={(e) =>
                                  setModelData({ ...modelData, eRole: e.target.value.trim() })
                                }
                              />
                            </InputGroup>
                            {(validate && !modelData.eRole) ? <TextError children={'This field is required'} /> : null}
                          </FormGroup>
                          <Row className="my-4">
                            <Col xs="12">
                              <div className="custom-control custom-control-alternative custom-checkbox">
                                <input
                                  className="custom-control-input"
                                  id="customCheckRegister"
                                  type="checkbox"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheckRegister"
                                >
                                  <span>
                                    I agree with the{" "}
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Privacy Policy
                                    </a>
                                  </span>
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <div className="text-right">
                            <Button variant="secondary" onClick={handleClose}>
                              Close
                            </Button>
                            <Button
                              className=""
                              color="primary"
                              type="button"
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          </div>
                        </Form>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SignupModel;
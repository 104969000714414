import React, { useState, useEffect } from "react";
import BlogList from "./BlogList";
import Sidebar from "components/MainLayout";

function BlogSection() {
  const comp1 = <BlogList />;
  const [compArr, setCompArr] = useState([comp1]);
  const [onTop, setOnTop] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
    setOnTop(false);
  }, [onTop]);

  return (
    <>
      <Sidebar compArr={compArr} setCompArr={setCompArr} setOnTop={setOnTop} />
    </>
  );
}

export default BlogSection;

import { RxDashboard } from "react-icons/rx"
import { FiUserCheck,FiServer,FiFileText } from "react-icons/fi"
import { AiOutlineLogout } from "react-icons/ai"
import { TbFileCertificate } from "react-icons/tb"



export const sidebarAdmin = [
    {
        title: "Dashboard",
        icon: <RxDashboard/>,
        link: "/dashboard"
    },
    {
        title: "User",
        icon: <FiUserCheck/>,
        link: "/user-section"
    },
    {
        title: "Blogs",
        icon: <FiFileText/>,
        link: "/blog-section"
    },
    {
        title: "Department",
        icon: <FiServer/>,
        link: "/department-section"
    },
    {
        title: "Queries",
        icon: <FiServer/>,
        link: "/queries-section"
    },
    {
        title: "Logout",
        icon: <AiOutlineLogout/>,
        link: "/"
    }
]


export const sidebarUser = [
    {
        title: "Dashboard",
        icon: <RxDashboard/>,
        link: "/dashboard"
    },
    {
        title: "Blogs",
        icon: <FiFileText/>,
        link: "/blog-section"
    },
    {
        title: "Logout",
        icon: <AiOutlineLogout/>,
        link: "/"
    }
]

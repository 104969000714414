import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import DemoNavbar from './DemoNavbar';
import bannerImg from "../assets/img/banner3.jpg"


function ThemeContainer({ mainComponent }) {
  const location = useLocation();
  const pageTitle = (location.pathname.split("/")[1])
  const [pageName, setPageName] = useState(pageTitle)

  const userId = sessionStorage.getItem("userId")

  useEffect(() => {
    setPageName(pageTitle)
  }, [pageName])
  
  const heroStyles = {
    backgroundImage: `url(${bannerImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position:'relative',
    // Add any other background-related styles as needed
  };
  const overLayStyles = {
    position:'absolute',
    height:'100%',
    width:'100%',
    background: 'linear-gradient(to bottom,rgba(0,0,0,0.8),rgba(0,0,0,0.2)) '
  };

  return (
    <div style={heroStyles} >
      <div style={overLayStyles}></div>
      <section  className={`section-profile-cover section-shaped my-0 mb-5 ${userId ? "center":""}` } style={{ height: userId ? "100vh" : "90vh" }}>
        {
          userId ? null : <DemoNavbar />
        }
        <div className={userId ? "" : "theme-container"}>
          {
            mainComponent
          }
        </div>
        {/* <div className="separator separator-bottom separator-skew ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            {
              userId ? null : <polygon
                className="fill-white"
                points="2560 0 2560 100 0 100"
              />
            }
          </svg>
        </div> */}
      </section>
    </div>
  )
}

export default ThemeContainer
import axios from 'axios';
// import * as _helper from './helper';
import {BASE_API_URL} from "../config/constant"

const axiosInstance = axios.create({ baseURL:BASE_API_URL });

let authToken = sessionStorage.getItem('token');
// let authToken = sessionStorage.getItem('token');
axiosInstance.interceptors.request.use(async req => {
    if (!authToken) {
        authToken = sessionStorage.getItem('token');
    }
    // console.log("BASE_API_URL", BASE_API_URL)
    req.headers.Authorization = authToken
    // req.headers.Authorization = `Bearer ${authToken}`
    return req;
});

axiosInstance.interceptors.response.use(
    async (response) => {
        // response.data.data = await _helper.decrypt(response?.data?.data);
        // console.log("Interceptor res", response)
    // console.log("BASE_API_URL", BASE_API_URL)

        return response;
    },
    (error) => {
        // _helper.sendResponse('error', error?.response?.data?.message);
        return Promise.reject((error.response && error.response.data) || 'Something went wrong')
    }
);

export default axiosInstance;

import api from '../index';
// import * as _helper from '../helper';
import * as URL from '../url';

export const UserById = async (id) => {
    try {
        const response = await api.get(URL.USER_BY_ID + id);
        // _helper.sendResponse('success', response.data.message)

        console.log("response user by id** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error, "<<")
        return
    }
}

export const GetUserList = async (count,pageNumber) => {
    try { 
        // console.log(val,"valval");
        const response = await api.get(URL.USER_LIST + "?"+ `count=${count}&pageNumber=${pageNumber}`);
        // _helper.sendResponse('success', response.data.message)

        console.log("response GetUserList** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error, "<<")
        return
    }
}

export const EmployeeList = async () => {
    try { 
        const response = await api.get(URL.EMPLOYEE_LIST );
        // _helper.sendResponse('success', response.data.message)

        console.log("response EmployeeList** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error, "<<")
        return
    }
}

export const EnableUser = async (id) => {
    try {
        const response = await api.patch(URL.ENABLE_USER + id);
        // _helper.sendResponse('success', response.data.message)

        console.log("response user by id** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error, "<<")
        return
    }
}
export const DisableUser = async (id) => {
    try {
        const response = await api.patch(URL.DISABLE_USER + id);
        // _helper.sendResponse('success', response.data.message)

        console.log("response user by id** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error, "<<")
        return
    }
}
export const BlockUser = async (id) => {
    try {
        const response = await api.patch(URL.BLOCK_USER + id);
        // _helper.sendResponse('success', response.data.message)

        console.log("response user by id** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error, "<<")
        return
    }
}
export const UnblockUser = async (id) => {
    try {
        const response = await api.patch(URL.UNBLOCK_USER + id);
        // _helper.sendResponse('success', response.data.message)

        console.log("response user by id** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error, "<<")
        return
    }
}


export const AddCertificate = async (body) => {
    try {
        const response = await api.post(URL.CERTIFICATE,body);
        console.log("response AddCertificate** ", response)
        return response
    } catch (error) {
        console.log(error, "<<")
        return
    }
}
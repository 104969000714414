import { useState, useEffect } from "react";
import {
  Container,
  Navbar,
  NavbarBrand,
  // NavbarToggler,
  Collapse,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { CONSTANTS } from "constant/Content";
import { FaBars } from "react-icons/fa";
import Login from "views/pages/auth/Login";
import useWindowSize from "hooks/useWindowSize";

function DemoNavbar(args) {
  const location = useLocation();
  const pageTitle = location.pathname.split("/")[1];

  const windowSize = useWindowSize();
  const [pageName, setPageName] = useState(pageTitle);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isScrolled, setIsScrolled] = useState(true);
  const [showLoginPage, setShowLoginPage] = useState(false);

  console.log(windowSize);

  useEffect(() => {
    if (windowSize.innerWidth < 568) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize.innerWidth]);

  useEffect(() => {
    setPageName(pageTitle);
  }, [pageName]);

  const toggle = () => setIsOpen(!isOpen);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth <= 568) {
  //       setIsMobile(true);
  //     } else {
  //       setIsMobile(false);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   handleResize();
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // scrollheight

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = window.pageYOffset;
      let scrollThreshold;
      if (isScrolled === "") {
        scrollThreshold = 600;
      } else {
        scrollThreshold = 500;
      }
      setIsScrolled(scrollHeight > scrollThreshold);
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navStyles = {
    backgroundColor:
      !isScrolled && pageName !== "blog" ? "transparent" : "white",
    zIndex: 2,
    color: isMobile ? "black" : "white",
    marginTop: isMobile || pageName === "blog" ? "" : !isScrolled ? "20px" : "",
    zIndex: 4,
  };
  const closeBtnStyles = {
    position: "relaitve",
    fontSize: "20px",
    color: "black",
  };

  return (
    <>
      <Navbar
        {...args}
        style={navStyles}
        className={
          isScrolled || pageName === "blog-list" || pageName === "blog"
            ? "shadow sticky"
            : "sticky"
        }
      >
        <Container>
          <NavbarBrand
            className={
              isScrolled || pageName === "blog" ? "text-dark" : "text-white"
            }
            href="/"
          >
            Emphasise Tech
          </NavbarBrand>
          {!isMobile ? (
            <Nav>
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <Link to="/">
                    <span
                      className={`nav-link-inner--text text-uppercase ${
                        pageName == ""
                          ? "themeBold"
                          : isScrolled || isMobile || pageName === "blog"
                          ? "text-dark"
                          : "text-white"
                      }`}
                    >
                      {" "}
                      {CONSTANTS.navbar.nav.navLink1}
                    </span>
                  </Link>
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <Link to="/about-page">
                    <span
                      className={`nav-link-inner--text text-uppercase ${
                        pageName == "about-page"
                          ? "themeBold"
                          : isScrolled || isMobile || pageName === "blog"
                          ? "text-dark"
                          : "text-white"
                      }`}
                    >
                      {CONSTANTS.navbar.nav.navLink2}
                    </span>
                  </Link>
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <span
                    className={`nav-link-inner--text text-uppercase ${
                      pageName == "service-page"
                        ? "themeBold"
                        : isScrolled || isMobile || pageName === "blog"
                        ? "text-dark"
                        : "text-white"
                    }`}
                  >
                    {CONSTANTS.navbar.nav.navLink3.title}
                  </span>
                </DropdownToggle>
                <DropdownMenu
                  style={{ backgroundColor: isScrolled ? "" : "transparent" }}
                >
                  <DropdownItem
                    to="/service-page?service=webGraphicDesigning"
                    tag={Link}
                  >
                    <span
                      className={`nav-link-inner--text text-uppercase ${
                        isScrolled || isMobile || pageName === "blog"
                          ? "text-dark dropdownItem2"
                          : "text-white"
                      }`}
                    >
                      {CONSTANTS.navbar.nav.navLink3.links.service1}
                    </span>
                  </DropdownItem>
                  <DropdownItem
                    to="/service-page?service=webDevelopment"
                    tag={Link}
                  >
                    <span
                      className={`nav-link-inner--text text-uppercase ${
                        isScrolled || isMobile || pageName === "blog"
                          ? "text-dark dropdownItem2"
                          : "text-white"
                      }`}
                    >
                      {CONSTANTS.navbar.nav.navLink3.links.service2}
                    </span>
                  </DropdownItem>
                  <DropdownItem to="/service-page?service=moblieApp" tag={Link}>
                    <span
                      className={`nav-link-inner--text text-uppercase ${
                        isScrolled || isMobile || pageName === "blog"
                          ? "text-dark dropdownItem2"
                          : "text-white"
                      }`}
                    >
                      {CONSTANTS.navbar.nav.navLink3.links.service3}
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <Link to="team-page">
                    <span
                      className={`nav-link-inner--text text-uppercase ${
                        pageName == "team-page"
                          ? "themeBold"
                          : isScrolled || isMobile || pageName === "blog"
                          ? "text-dark"
                          : "text-white"
                      }`}
                    >
                      {" "}
                      {CONSTANTS.navbar.nav.team}
                    </span>
                  </Link>
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <Link to="contact-page">
                    <span
                      className={`nav-link-inner--text text-uppercase ${
                        pageName == "contact-page"
                          ? "themeBold"
                          : isScrolled || isMobile || pageName === "blog"
                          ? "text-dark"
                          : "text-white"
                      }`}
                    >
                      {" "}
                      {CONSTANTS.navbar.nav.navLink4}
                    </span>
                  </Link>
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <Link to="blog-list">
                    <span
                      className={`nav-link-inner--text text-uppercase ${
                        pageName == "blog-list"
                          ? "themeBold"
                          : isScrolled || isMobile || pageName === "blog"
                          ? "text-dark"
                          : "text-white"
                      }`}
                    >
                      {" "}
                      {CONSTANTS.navbar.nav.navLink5}
                    </span>
                  </Link>
                </DropdownToggle>
              </UncontrolledDropdown>
              {/* <p
                style={{ position: "relative", transform: "translateX(300px)" }}
                onClick={() => setShowLoginPage(true)}
              ></p> */}
            </Nav>
          ) : (
            <div>
              {/* <NavbarToggler className="bg-dark" onClick={toggle} /> */}
              <FaBars
                className={isScrolled ? "text-dark" : "text-white"}
                onClick={() => toggle()}
              />
              <Collapse isOpen={isOpen} navbar>
                <h4
                  className="text-right closeBtn p-o m-0"
                  style={closeBtnStyles}
                  onClick={() => setIsOpen(false)}
                >
                  X
                </h4>
                <Nav className="me-auto" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <Link to="/">
                        <span
                          className={`nav-link-inner--text text-uppercase ${
                            pageName == ""
                              ? "themeBold"
                              : isScrolled || isMobile || pageName === "blog"
                              ? "text-dark"
                              : "text-white"
                          }`}
                        >
                          {" "}
                          {CONSTANTS.navbar.nav.navLink1}
                        </span>
                      </Link>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <Link to="/about-page">
                        <span
                          className={`nav-link-inner--text text-uppercase ${
                            pageName == "about-page"
                              ? "themeBold"
                              : isScrolled || isMobile || pageName === "blog"
                              ? "text-dark"
                              : "text-white"
                          }`}
                        >
                          {CONSTANTS.navbar.nav.navLink2}
                        </span>
                      </Link>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <span
                        className={`nav-link-inner--text text-uppercase ${
                          pageName == "service-page"
                            ? "themeBold"
                            : isScrolled || isMobile || pageName === "blog"
                            ? "text-dark"
                            : "text-white"
                        }`}
                      >
                        {CONSTANTS.navbar.nav.navLink3.title}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu
                      style={{
                        backgroundColor: isScrolled ? "" : "transparent",
                      }}
                    >
                      <DropdownItem
                        to="/service-page?service=webGraphicDesigning"
                        tag={Link}
                      >
                        <span
                          className={`nav-link-inner--text text-uppercase ${
                            isScrolled || isMobile || pageName === "blog"
                              ? "text-dark dropdownItem2"
                              : "text-white"
                          }`}
                        >
                          {CONSTANTS.navbar.nav.navLink3.links.service1}
                        </span>
                      </DropdownItem>
                      <DropdownItem
                        to="/service-page?service=webDevelopment"
                        tag={Link}
                      >
                        <span
                          className={`nav-link-inner--text text-uppercase ${
                            isScrolled || isMobile || pageName === "blog"
                              ? "text-dark dropdownItem2"
                              : "text-white"
                          }`}
                        >
                          {CONSTANTS.navbar.nav.navLink3.links.service2}
                        </span>
                      </DropdownItem>
                      <DropdownItem
                        to="/service-page?service=moblieApp"
                        tag={Link}
                      >
                        <span
                          className={`nav-link-inner--text text-uppercase ${
                            isScrolled || isMobile || pageName === "blog"
                              ? "text-dark dropdownItem2"
                              : "text-white"
                          }`}
                        >
                          {CONSTANTS.navbar.nav.navLink3.links.service3}
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <Link to="team-page">
                        <span
                          className={`nav-link-inner--text text-uppercase ${
                            pageName == "team-page"
                              ? "themeBold"
                              : isScrolled || isMobile || pageName === "blog"
                              ? "text-dark"
                              : "text-white"
                          }`}
                        >
                          {" "}
                          {CONSTANTS.navbar.nav.team}
                        </span>
                      </Link>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <Link to="contact-page">
                        <span
                          className={`nav-link-inner--text text-uppercase ${
                            pageName == "contact-page"
                              ? "themeBold"
                              : isScrolled || isMobile || pageName === "blog"
                              ? "text-dark"
                              : "text-white"
                          }`}
                        >
                          {" "}
                          {CONSTANTS.navbar.nav.navLink4}
                        </span>
                      </Link>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <Link to="blog-list">
                        <span
                          className={`nav-link-inner--text text-uppercase ${
                            pageName == "blog-list"
                              ? "themeBold"
                              : isScrolled || isMobile || pageName === "blog"
                              ? "text-dark"
                              : "text-white"
                          }`}
                        >
                          {" "}
                          {CONSTANTS.navbar.nav.navLink5}
                        </span>
                      </Link>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                </Nav>
              </Collapse>
            </div>
          )}
        </Container>
      </Navbar>
      {showLoginPage ? (
        <div className="login-area-overlay ">
          <div className="text-right">
            <h4
              className="text-white pr-5 mt-4 closeBtn"
              onClick={() => setShowLoginPage(false)}
            >
              X
            </h4>
            <Login />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default DemoNavbar;

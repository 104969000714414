import api from '../index';
// import * as _helper from '../helper';
import * as URL from '../url';

export const SignIn = async (body) => {
    try {
        const response = await api.post(URL.SIGN_IN, body);
        if(!response?.data.success){
            // _helper.sendResponse('error', response.data.message)
        }else{
            console.log("response login** ", response)
            return response
        }
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}

export const SignUp = async (body) => {
    try {
        const response = await api.post(URL.SIGN_UP, body);
        // _helper.sendResponse('success', response.data.message)

        console.log("response signup** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}


// export const ForgetPassword = async (body) => {
//     try {
//         const response = await api.put(URL.FORGET_PASSWORD, body);
//         _helper.sendResponse('success', response.data.message)

//         console.log("ForgetPassword response", response)
//         return response
//     } catch (error) {
//         _helper.sendResponse('error', error.message)
//         console.log(error,"<<")
//         return
//     }
// }

// export const ResetPassword = async (body) => {
//     try {
//         const response = await api.put(URL.RESET_PASSWORD, body);
//         _helper.sendResponse('success', response.data.message)

//         console.log("Password reset successfully", response)
//         return response
//     } catch (error) {
//         _helper.sendResponse('error', error.message)
//         console.log(error,"<<")
//         return
//     }
// }

// export const VerifyOTP = async (body) => {
//     try {
//         const response = await api.post(URL.VERIFY_OTP, body);
//         _helper.sendResponse('success', response.data.message)

//         console.log("response VerifyOTP** ", response)
//         return response
//     } catch (error) {
//         _helper.sendResponse('error', error.message)
//         console.log(error,"<<")
//         return
//     }
// }

// export const ChangePassword = async (body) => {
//     try {
//         const response = await api.put(URL.CHANGE_PASSWORD, body);
//         _helper.sendResponse('success', response.message)

//         console.log("Password changed successfully", response)
//         return response
//     } catch (error) {
//         _helper.sendResponse('error', error.message)
//         console.log(error,"<<")
//         return
//     }
// }

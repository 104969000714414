export const emailValidation = (email) => {
    const regexp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return regexp.test(email);
}
export const passwordValidation = (password) => {
    const regexp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/
    return regexp.test(password);
}
export const contactNumberValidation = (contactNumber) => {
    const regexp = /^[6-9]\d{9}$/
    // const regexp = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6-9]\d{9}$/
    return regexp.test(contactNumber);
}
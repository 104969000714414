import React, { useEffect, useState } from "react";

// core components
import DemoNavbar from "components/DemoNavbar";
import MainContent from "components/MainContent";
import PlanTextBox from "components/PlanTextBox";
import OnTopBtn from "components/OnTopBtn";
import HeroSection from "components/HeroSection";
import ServiceIntro from "components/ServiceIntro";
import SimpleFooter from "components/SimpleFooter";

function Service() {
  
  const [onTop, setOnTop] = useState(false)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
    setOnTop(false)
  }, [onTop])
  return (
    <>
      <DemoNavbar />
      <HeroSection/>
      <MainContent  />
      <ServiceIntro/>
      <PlanTextBox />
      <OnTopBtn setOnTop={setOnTop} />
      <SimpleFooter/>
    </>
  );
}


export default Service;

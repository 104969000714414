import api from '../index';
// import * as _helper from '../helper';
import * as URL from '../url';

export const ContactList = async () => {
    try {
        const response = await api.get(URL.CONTACT_NUMBER);
        console.log("response ContactList** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error, "<<")
        return
    }
}
export const SearchContact = async (data) => {
    try {
        const response = await api.get(`${URL.CONTACT_NUMBER}/search?key=${data}`);
        console.log("response SearchContact** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error, "<<")
        return
    }
}

export const CreateContact = async (body) => {
    try {
        const response = await api.post(URL.CONTACT_NUMBER, body);
        console.log("response CreateContact** ", response)
        return response
    } catch (error) {
        console.log(error, "<<")
        return
    }
}
export const UpdateContact = async (id, body) => {
    try {
        const response = await api.patch(URL.CONTACT_NUMBER + "/" + id, body);
        console.log("response UpdateContact** ", response)
        return response
    } catch (error) {
        console.log(error, "<<")
        return
    }
}

export const EnableById = async (id) => {
    try {
        const response = await api.patch(URL.ENABLE_CONTACT_NUMBER + id);
        console.log("response EnableById** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error, "<<")
        return
    }
}

export const AddToFavourite = async (id) => {
    try {
        const response = await api.patch(URL.FAVOURITE_CONTACT_NUMBER + id);
        console.log("response AddToFavourite** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error, "<<")
        return
    }
}

export const DisableById = async (id) => {
    try {
        const response = await api.delete(URL.DISABLE_CONTACT_NUMBER + id);
        console.log("response DisableById** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error, "<<")
        return
    }
}
export const DeletedContact = async (id) => {
    try {
        const response = await api.delete(URL.DELETE_CONTACT + id);
        console.log("response DeletedContact** ", response)
        return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error, "<<")
        return
    }
}






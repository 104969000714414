import React, { useState } from "react";
import { FaPhoneAlt } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import * as _ from 'lodash';
import { AddQuery } from "services/api/query";

import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";
import { CONSTANTS } from "constant/Content";

function ContactForm() {
  const [showLoginPage, setShowLoginPage] = useState(false)

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  })

  const handleSubmit = async () => {
    let checkData = { ...formData }
    const notEmpty = await _.values(checkData).every(_.negate(_.isEmpty));

    if (notEmpty) {
      const response = await AddQuery(formData)
      if (response) {
        setFormData({
          name: "",
          email: "",
          message: ""
        })
        setShowLoginPage(true)
      }
    }
  }

  const heroStyles = {
    marginBottom: "200px",
    // Add any other background-related styles as needed
  };
  const iconStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
    // Add any other background-related styles as needed
  };

  return (
    <>
      <section className="section section-lg section-shaped " style={heroStyles}>
        <Container className="py-md">
          <Row style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', position: "relative" }} 
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          >
            <Col className="text-white" md={5} style={{ backgroundColor: "#423ead" }} >
              <div className="p-5">
                <h4 className="whiteBold text-uppercase mb-3">Contact Us</h4>
                <div style={iconStyles} className="mb-3">
                  <div className="custom-icon">
                    <FaPhoneAlt />
                  </div>
                  <div>
                    <p className="themeBold text-right m-0">Phone</p>
                    <p className="whiteBold ">{CONSTANTS.contactPage.contentBar.phone.line2}</p>
                  </div>
                </div>
                {/* <h2 className="whiteBold">{CONSTANTS.contactPage.contentBar.phone.title}</h2> */}
                <p>{CONSTANTS.contactPage.contentBar.phone.line1}</p>
              </div>

              <div className="p-5">
                <div style={iconStyles} className="mb-3" >
                  <div className="custom-icon">
                    <GrMail />
                  </div>
                  <div >
                    <p className="themeBold text-right m-0">Email</p>
                    <p className="whiteBold">{CONSTANTS.contactPage.contentBar.email.line2}</p>
                  </div>
                </div>
                {/* <h2 className="whiteBold">{CONSTANTS.contactPage.contentBar.email.title}</h2> */}
                <p>{CONSTANTS.contactPage.contentBar.email.line1}</p>

              </div>
            </Col>
            <Col className="p-0 m-0" md={7} >
              <Card style={{ height: "100%" }} >
                <CardBody>
                  <div className="my-4">
                    <h4 className="textBold text-uppercase">{CONSTANTS.contactPage.contentBar.form.title}</h4>
                  </div>
                  <form>
                    <div >
                      <input
                        className="w-100 mb-3 pl-3 py-2  input-group-alternative"
                        type="text"
                        placeholder={CONSTANTS.contactPage.contentBar.form.field1}
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      />
                    </div>
                    <div >
                      <input
                        className="w-100 mb-3 pl-3 py-2 input-group-alternative"
                        type="email"
                        placeholder={CONSTANTS.contactPage.contentBar.form.field2}
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      />
                    </div>
                    <div >
                      <textarea
                        className="w-100 pl-3 py-2 input-group-alternative"
                        placeholder={CONSTANTS.contactPage.contentBar.form.field3}
                        rows="5"
                        value={formData.message}
                        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                      ></textarea>
                    </div>

                    <div className="">
                      <Button
                        className="my-4 px-5"
                        style={{ backgroundColor: "#ed03be", color: "white", border: "none" }}
                        onClick={() => {
                          handleSubmit()
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
             {
      showLoginPage ?
        <div className="query-overlay ">
          <div className="text-right">
            <h4 className="text-white pr-5 mt-4 closeBtn" onClick={() => setShowLoginPage(false)}>X</h4>
          </div>
            <h2 className="text-white text-center" style={{position:'relative',transform:'translateY(200px)'}}>Thank you for your message!</h2>
        </div>
        : null
    }
          </Row>
        </Container>
      </section>
    </>
  );
}


export default ContactForm;

import React from 'react'
import UserTable from './UserTable'
import BasicLayout from 'components/BasicLayout'


function UserSection() {
  const SampleComponent = BasicLayout(UserTable)
  return (
    <SampleComponent />
  )
}

export default UserSection
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import EmployeeCard from "./EmployeeCard";
import { DepartmentList } from "services/api/department";

function MainContent() {
  const [deparmentList, setDeparmentList] = useState();

  useEffect(() => {
    async function getData() {
      let res = await DepartmentList();
      setDeparmentList(res?.data?.data);
    }
    getData();
  }, []);

  const [departmentId, setDepartmentId] = useState("6436510e3cea19dc15a67d98");

  const departmentStyles = {
    cursor: "Pointer",
  };

  return (
    <>
      <div
        className="position-relative "
        data-aos="fade-up"
        data-aos-offset="150"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <section className="section section-hero section-shaped">
          <Container>
            <Row className="mb-5">
              <Col className="col-12 text-center">
                <div>
                  <h1>Meet our talented & experienced employees</h1>
                </div>
              </Col>
            </Row>
            <Row>
              {deparmentList?.length
                ? deparmentList?.map((department) => {
                    return (
                      <>
                        <Col key={department?._id}>
                          <div className="text-center">
                            {/* <Button variant="white" className={department == departmentId ? "font-weight-bold" : ""} style={departmentStyles} onClick={()=>setDepartmentId(department)}>{department}</Button> */}
                            <p
                              className={
                                department?._id == departmentId
                                  ? "font-weight-bold textBold"
                                  : "font-weight-bold"
                              }
                              style={departmentStyles}
                              onClick={() => setDepartmentId(department?._id)}
                            >
                              {department?.name}
                            </p>
                          </div>
                        </Col>
                      </>
                    );
                  })
                : null}
            </Row>
            <EmployeeCard departmentId={departmentId} />
          </Container>
        </section>
      </div>
    </>
  );
}

export default MainContent;

import React,{useState,useEffect} from 'react'
import { UncontrolledTooltip,Button } from 'reactstrap'
import { AiOutlineArrowUp } from "react-icons/ai"

function OnTopBtn({ setOnTop }) {
  const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
          if (window.pageYOffset > 200  ) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        });
      }, []);
    return (
        <>
        {showButton ? 
        <div onClick={() => setOnTop(true)} className="onTop-btn-container">
            <div className="onTop-btn center" id="tooltip38321129" >
            <AiOutlineArrowUp/>
            </div>
            <UncontrolledTooltip
                delay={0}
                placement="left"
                target="tooltip38321129"
                trigger="hover focus"
            >
                Back to Top
            </UncontrolledTooltip>
        </div>:null}
        </>
    )
}

export default OnTopBtn
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { EmployeeList } from "services/api/user";
import userImg from "../../../assets/img/default-user.webp";
import mdImage from "../../../assets/employees/dhanji.jpg";
import niaamuImg from "../../../assets/employees/niaamu.jpg";
import roshaniImg from "../../../assets/employees/roshni.jpg";
import angadhImg from "../../../assets/employees/angadh.jpg";
import rajeshImg from "../../../assets/employees/rajesh.jpg";
import abhayImg from "../../../assets/employees/abhay.jpg";
import Loader from "../../../components/Loader";

function EmployeeCard({ departmentId }) {
  const [employeeList, setEmployeeList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    async function getData() {
      setIsLoader(true);
      let res = await EmployeeList();
      setEmployeeList(res?.data?.data?.filter((e) => e.role != "admin"));
      setIsLoader(false);
    }
    getData();
  }, []);

  return (
    <div className="position-relative mt-3">
      {isLoader ? (
        <Loader loaded={isLoader} />
      ) : (
        <section className="section section-hero section-shaped">
          <Container>
            <Row>
              {employeeList?.length &&
                employeeList
                  .filter((employee) => employee.department == departmentId)
                  .map((employee) => {
                    return (
                      <>
                        <Col key={employee?._id}>
                          <div className="employee-card">
                            <div className="employee-img-container mt-3 shadow-sm">
                              <img
                                loading="lazy"
                                src={
                                  employee?.name == "Niaamu Deen"
                                    ? niaamuImg
                                    : employee?.eRole == "Managing Director"
                                    ? mdImage
                                    : employee?.name == "Roshani Mishara"
                                    ? roshaniImg
                                    : employee?.name == "Angadh Ojha"
                                    ? angadhImg
                                    : employee?.name == "Rajesh Kumar"
                                    ? rajeshImg
                                    : employee?.name == "Abhay Upadhyay"
                                    ? abhayImg
                                    : userImg
                                }
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                            <div className="mt-3 text-center ">
                              <h5 className="font-weight-bold  p-0 m-0">
                                {employee.name}
                              </h5>
                              <p
                                className="text-dark"
                                style={{ fontSize: "14px" }}
                              >
                                {employee.eRole}
                              </p>
                              {/* <p className="text-white">{employee.eRole}</p> */}
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })}
            </Row>
          </Container>
        </section>
      )}
    </div>
  );
}

export default EmployeeCard;

import React, { useEffect, useState } from "react";
import { Row, Col, Container, Table, Button } from "reactstrap";
import {
  DepartmentList,
  EnableById,
  DisableById,
  DeleteById,
} from "services/api/department";
import SignupModel from "../../../components/Modal";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useLocation, Link } from "react-router-dom";
import { GetQuery, GetQueryById, AcceptQueryById } from "services/api/query";
import { toastNotification } from "components/Toast";
import TContainer from "components/Toast";

function QueryTable() {
  const location = useLocation();
  const [section, setSection] = useState(location.pathname.split("/")[1]);
  const [queryListData, setQueryListData] = useState();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [queryId, setQueryId] = useState(
    new URLSearchParams(location.search).get("id")
  );
  const [userData, setUserData] = useState("");
  console.log(userData, ">>");
  // const [skipNo, setSkipNo] = useState(0)

  // pagination
  const [count, setCount] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    async function fetchData() {
      let res = await GetQuery();
      setQueryListData(res?.data?.data);

      let res2 = await GetQueryById(queryId);
      setUserData(res2?.data?.data);
    }
    fetchData();
  }, [refresh]);

  const handleAccept = async () => {
    let res = await AcceptQueryById(queryId);
    await toastNotification("success", "Query Accepted succesfully");
    setRefresh(!refresh);
    setQueryId("");
  };
  const handleAddUser = () => {
    setShow(true);
  };
  const handleEnableUser = async (id) => {
    console.log(id, "id");
    let res = await EnableById(id);
    setRefresh(!refresh);
    console.log(res, "res<<<<<<<<<");
  };
  const handleDisableUser = async (id) => {
    console.log(id, "id");
    let res = await DisableById(id);
    setRefresh(!refresh);
    console.log(res, "res<<<<<<<<<");
  };
  const handleDeleteUser = async (id) => {
    console.log(id, "id");
    let res = await DeleteById(id);
    setRefresh(!refresh);
    console.log(res, "res<<<<<<<<<");
  };
  // const handleUnblockUser = async (id) => {
  //     let res = await UnblockUser(id)
  //     setRefresh(!refresh)
  // }
  const hanldeIncrement = async (el, i) => {
    setPageNumber(pageNumber + 1);
    setRefresh(!refresh);
  };
  const handleDecrement = async (el, i) => {
    setPageNumber(pageNumber - 1);
    setRefresh(!refresh);
  };

  return (
    <>
      {show ? (
        <SignupModel
          section={section}
          refresh={refresh}
          setRefresh={setRefresh}
          queryListData={queryListData}
          setQueryListData={setQueryListData}
          setShow={setShow}
          show={show}
        />
      ) : null}
      {!queryId ? (
        <div className="mt-5 mt-md-0">
          <Container className="mb-5 ">
            <h4>Queries Section</h4>
          </Container>
          <Container className="mb-3 sideByside">
            <select
              value={count}
              onChange={(e) => {
                setCount(e.target.value);
                setPageNumber(1);
                setRefresh(!refresh);
              }}
              style={{ width: "100px" }}
            >
              {/* <option hidden selected>Select page no.</option> */}
              <option value="5" selected>
                5
              </option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
            </select>
            {/* <Button color="primary" onClick={handleAddUser}>Add</Button> */}
          </Container>
          <Container className="shape-container d-flex align-items-center ">
            {queryListData?.length ? (
              <Table bordered size="sm" className="user-table">
                <thead>
                  <tr className="user-table">
                    <th>S.no</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th className="text-center">Actions </th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  {queryListData?.map((val, i) => {
                    return (
                      <>
                        <tr key={i} className="user-table-col">
                          <td>{i + 1}</td>
                          <td>{val.name}</td>
                          <td>{val.email}</td>
                          <td>{val.status}</td>
                          <td>
                            <Link to={`/queries-section?id=${val._id}`}>
                              <Button color="primary">Detail</Button>
                            </Link>
                          </td>
                          {/* <td >
                                                            <div>
                                                                {
                                                                    val.status == 0 ? <div>
                                                                        <Button color="primary" onClick={() => handleEnableUser(val._id)}>Enable</Button>
                                                                        <Button color="primary" onClick={() => handleDeleteUser(val._id)}>Delete</Button>
                                                                    </div> : val.status == 1 ? <div>
                                                                        <Button color="primary" onClick={() => handleDisableUser(val._id)}>Disable</Button>
                                                                        <Button color="primary" onClick={() => handleDeleteUser(val._id)}>Delete</Button>
                                                                    </div> : <div>
                                                                        <Button disabled color="primary" onClick={() => handleEnableUser(val._id)}>Enable</Button>
                                                                        <Button color="primary" onClick={() => handleDisableUser(val._id)}>Disable</Button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td> */}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div className="text-center w-100">
                <p>No Results Found</p>
              </div>
            )}
          </Container>
          <div className="mt-3">
            <Container>
              <Button
                color="primary"
                disabled={pageNumber == 1 ? true : false}
                onClick={() => handleDecrement()}
              >
                <FaChevronLeft />
              </Button>
              <span className="whiteBold mr-2">{pageNumber}</span>
              <Button
                color="primary"
                disabled={queryListData?.length < count ? true : false}
                onClick={() => hanldeIncrement()}
              >
                <FaChevronRight />
              </Button>
            </Container>
          </div>
        </div>
      ) : (
        <section className="shadow-sm p-5 mt-5 mt-md-0">
          <div className="mb-5 ">
            <h4>Queries Section</h4>
          </div>
          <div className="mb-5">
            <Container>
              <Row>
                <Col md={4}>
                  <p>Name</p>
                </Col>
                <Col md={8}>
                  {/* <input className='pl-3 w-100 '  readOnly value={userData?.name}/> */}
                  <p>{userData?.name}</p>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col md={4}>
                  <p>Email</p>
                </Col>
                <Col md={8}>
                  {/* <input className='pl-3 w-100' readOnly value={userData?.email}/> */}
                  <p>{userData?.email}</p>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col md={4}>
                  <p>Message</p>
                </Col>
                <Col md={8}>
                  {/* <textarea
                                        className='pl-3 w-100' readOnly value={userData?.message} rows="5" >
                                        </textarea> */}
                  <p>{userData?.message}</p>
                </Col>
              </Row>
            </Container>
          </div>
          <div>
            <Button color="primary" onClick={() => setQueryId("")}>
              Back
            </Button>
            <Button color="success" onClick={() => handleAccept()}>
              ACCEPT
            </Button>
          </div>
        </section>
      )}
      <TContainer />
    </>
  );
}

export default QueryTable;

import api from '../index';
// import * as _helper from '../helper';
import * as URL from '../url';

export const DepartmentList = async () => {
    try {
        const response = await api.get(URL.DEPARTMENT);
            console.log("response DepartmentList** ", response)
            return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}

export const CreateDepartment = async (body) => {
    try {
        const response = await api.post(URL.DEPARTMENT,body);
            console.log("response CreateDepartment** ", response)
            return response
    } catch (error) {
        console.log(error,"<<")
        return
    }
}

export const EnableById = async (id) => {
    try {
        const response = await api.patch(URL.ENABLE+id);
            console.log("response EnableById** ", response)
            return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}

export const DisableById = async (id) => {
    try {
        const response = await api.patch(URL.DISABLE+id);
            console.log("response DisableById** ", response)
            return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}

export const DeleteById = async (id) => {
    try {
        const response = await api.patch(URL.DELETE+id);
            console.log("response DeleteById** ", response)
            return response
    } catch (error) {
        // _helper.sendResponse('error', error.message)
        console.log(error,"<<")
        return
    }
}






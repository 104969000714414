import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { sidebarAdmin,sidebarUser } from './Panel';
import useWindowSize from './helper';
import { FaBars } from "react-icons/fa"
import { AiOutlineClose } from "react-icons/ai"
import {
    Row,
    Col
} from "reactstrap";
import OnTopBtn from './OnTopBtn';

function Sidebar({ setOnTop, compArr }) {

    const userId = sessionStorage.getItem("userId")

    const windowSize = useWindowSize();
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [navtabOpen, setNavtabOpen] = useState(false)

    useEffect(() => {
        if (windowSize.width <= 568) {
            setNavtabOpen(true)
        } else {
            setNavtabOpen(false)
        }

    }, [windowSize]);

    const handleClick = () => {
        setSidebarOpen(!sidebarOpen)
    }

    return (
        <>
            <Row className="m-0 p-0 nav-tab py-1">
                <Col className="col-3">
                    <h4 className='whiteBold mt-3 ml-4'>Emphasise</h4>
                </Col>
                <Col className="col-2 offset-7 end">
                    <button onClick={() => handleClick()} className="toggle-btn" >
                        <span>{sidebarOpen ? <AiOutlineClose  /> : (navtabOpen && !sidebarOpen ? <FaBars /> : "")} </span>
                    </button>
                </Col>
            </Row>
            <div className='d-flex row '>
                <div className='col-3'>
                    <div className={sidebarOpen ? "showSideBar" : "sidebar"}
                    >
                        <ul className='sidebar-list mt-5  w-75'>
                            {
                                (userId == "62f7f79db1d7b2a7449b2921" ? sidebarAdmin : sidebarUser ).map((value, index) => {
                                    console.log(value.title)
                                    return (
                                        <>
                                            <li key={index} className="sidebar-list-row mb-4">
                                                <div className='d-flex'>
                                                    <span className='sidebar-icon'>{value.icon}</span>
                                                    <Link to={value?.link} ><p className='text-white'>{value.title}</p></Link>
                                                </div>
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className='col-9'>
                    <div className='main-container-siderbar'>
                        {compArr}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar;
import React from 'react'
import { Container, Row, Col } from "reactstrap";
import img1 from "../assets/img/emphasise/img5.jpeg"
import designImg from "../assets/img/emphasise/new-service-one.svg"
import prototypeImg from "../assets/img/emphasise/new-service-two.svg"
import testImg from "../assets/img/emphasise/new-service-three.svg"
import deleiveryImg from "../assets/img/emphasise/new-service-four.svg"
import { CONSTANTS } from 'constant/Content';

function ServiceProcess() {
    return (
        <>
            <Container className="shape-container d-flex align-items-ceid pt-lg" >
                <div className="col px-0">
                    <Row className="justify-content-center mb-5"
                    data-aos="slide-right"
                    data-aos-offset="300"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out">
                        <Col lg="4">
                            <div className="imgStyle p-5">
                                <img className="imgStyle" src={designImg} />
                            </div>
                        </Col>
                        <Col className="text-left" lg="8">
                            <p className='label py-1'>{CONSTANTS.servicePage.process.step1.tag}</p>
                            <h1 className='textBold'>{CONSTANTS.servicePage.process.step1.line1}</h1>
                            <p>{CONSTANTS.servicePage.process.step1.line2}</p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mb-5"
                    data-aos="slide-left"
                    data-aos-offset="250"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out">
                        <Col className="text-left" lg="8">
                            <p className='label py-1'>{CONSTANTS.servicePage.process.step2.tag}</p>
                            <h1 className='textBold'>{CONSTANTS.servicePage.process.step2.line1}</h1>
                            <p>{CONSTANTS.servicePage.process.step2.line2}</p>
                        </Col>
                        <Col lg="4">
                            <div className="imgStyle p-5">
                                <img className="imgStyle" src={prototypeImg} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mb-5"
                    data-aos="slide-right"
                    data-aos-offset="300"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    >
                        <Col lg="4">
                            <div className="imgStyle p-5">
                                <img className="imgStyle" src={testImg} />
                            </div>
                        </Col>
                        <Col className="text-left" lg="8">
                            <p className='label py-1'>{CONSTANTS.servicePage.process.step3.tag}</p>
                            <h1 className='textBold'>{CONSTANTS.servicePage.process.step3.line1}</h1>
                            <p>{CONSTANTS.servicePage.process.step3.line2}</p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center"
                    data-aos="slide-left"
                    data-aos-offset="250"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    >
                        <Col className="text-left" lg="8">
                            <p className='label py-1'>{CONSTANTS.servicePage.process.step4.tag}</p>
                            <h1 className='textBold'>{CONSTANTS.servicePage.process.step4.line1}</h1>
                            <p>{CONSTANTS.servicePage.process.step4.line2}</p>
                        </Col>
                        <Col lg="4">
                            <div className="imgStyle p-5">
                                <img className="imgStyle" src={deleiveryImg} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
}

export default ServiceProcess
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserById } from "services/api/user";
import ThemeContainer from "./ThemeContainer";
import { Container, Row, Col } from "reactstrap";
import { CONSTANTS } from "constant/Content";

function Test() {
  const location = useLocation();
  const pageTitle = location.pathname.split("/")[1];
  const [pageName, setPageName] = useState(pageTitle);

  const serviceTitle = new URLSearchParams(location.search).get("service");
  const [serviceName, setServiceName] = useState(serviceTitle);
  useEffect(() => {
    setServiceName(serviceTitle);
    setPageName(pageTitle);
  }, [serviceTitle, pageName]);

  const [userData, setUserData] = useState();
  let userId = sessionStorage.getItem("userId");

  useEffect(() => {
    async function getData() {
      let response = await UserById(userId);
      setUserData(response?.data?.data);
    }
    getData();
  }, []);

  const pStyles = {
    backgroundColor: "#ed03be",
    color: "white",
    fontWeight: "400",
    textAlign: "center",
    padding: "3px 0",
  };
  return (
    <>
      <Container className="shape-container d-flex align-items-center py-lg ">
        <div className="col px-0">
          <Row className="align-items-center justify-content-center hero-row ">
            <Col
              className="text-left "
              lg="12"
              data-aos="slide-right"
              data-aos-duration="1500"
            >
              <h1 className="text-white font-weight-bold text-center m-0">
                {pageName == ""
                  ? "homepage"
                  : pageName == "about-page"
                  ? CONSTANTS.aboutUsPage.heroSection.title
                  : pageName == "contact-page"
                  ? CONSTANTS.contactPage.heroSection.title
                  : pageName == "team-page"
                  ? CONSTANTS.team.heroSection.line1
                  : pageName == "dashboard"
                  ? `Welcome  ${userData?.name ? userData.name : ""}`
                  : serviceName == "webGraphicDesigning"
                  ? CONSTANTS.servicePage.heroSection.category1.title
                  : serviceName == "webDevelopment"
                  ? CONSTANTS.servicePage.heroSection.category2.title
                  : serviceName == "moblieApp"
                  ? CONSTANTS.servicePage.heroSection.category3.title
                  : pageName == "blog-list"
                  ? "Blog-Section"
                  : null}
              </h1>
              <p className="m-0 " style={pStyles}>
                {pageName == ""
                  ? "homepage"
                  : pageName == "about-page"
                  ? CONSTANTS.aboutUsPage.heroSection.line1
                  : pageName == "team-page"
                  ? CONSTANTS.team.heroSection.line2
                  : pageName == "contact-page"
                  ? CONSTANTS.contactPage.heroSection.line1
                  : pageName == "blog-list"
                  ? "Let's Explore Together"
                  : null}
              </p>
              {pageName == "service-page" ? (
                <p className="m-0 " style={pStyles}>
                  {serviceName == "webGraphicDesigning"
                    ? CONSTANTS.servicePage.heroSection.category1.line1
                    : serviceName == "webDevelopment"
                    ? CONSTANTS.servicePage.heroSection.category2.line1
                    : serviceName == "moblieApp"
                    ? CONSTANTS.servicePage.heroSection.category3.line1
                    : null}
                </p>
              ) : null}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

function HeroSection() {
  const comp = <Test />;
  return (
    <>
      <ThemeContainer mainComponent={comp} />
    </>
  );
}
export default HeroSection;

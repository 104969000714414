import React, { useEffect, useState } from "react";
import BasicLayout from "components/BasicLayout";
import { UserById } from "services/api/user";

const Test = () => {
  const [userData, setUserData] = useState();
  let userId = sessionStorage.getItem("userId");

  useEffect(() => {
    async function getData() {
      let response = await UserById(userId);
      setUserData(response?.data?.data);
    }
    getData();
  }, []);
  return (
    <>
      <div className="text-center mt-5 mt-md-0">
        <h1>Welcome {userData?.name ? userData.name : ""}</h1>
      </div>
    </>
  );
};

function Dashboard() {
  const SampleComponent = BasicLayout(Test);
  return <SampleComponent />;
}

export default Dashboard;

import React, { useState } from 'react'
import CountUp from "react-countup"
import { Container, Row, Col } from 'reactstrap'
import ScrollTrigger from 'react-scroll-trigger'
import {BsCup} from "react-icons/bs"
import {VscSmiley} from "react-icons/vsc"
import {RxGlobe} from "react-icons/rx"
import {VscRocket} from "react-icons/vsc"

function Counter() {
    const [counterOn, setCounterOn] = useState(false)

    let counterArray = [
        {
            title: "Project Completed",
            counter: 100,
            icon:<VscRocket/>
        },
        {
            title: "Happy Clients",
            counter: 20,
            icon:<VscSmiley/>
        },
        // {
        //     title: "Cup of Coffee",
        //     counter: 500,
        //     icon:<BsCup/>
        // },
        {
            title: "Real Professionals",
            counter: 300,
            icon:<RxGlobe/>
        },
    ]

    const headingStyles = {
        color: "#ed03be",
        fontWeight:"bold"
    }
    const iconStyles = {
        fontSize:"30px"
    }
    return (
        <Container className="eachSectionMarginTop" >
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                <Row>
                    {
                        counterArray.map(e => {
                            return (
                                <>
                                    <Col md={4}>
                                        <div className='text-center'>
                                            <p style={iconStyles}>{e?.icon}</p>
                                            <h1 style={headingStyles}>
                                                {
                                                    counterOn && <CountUp start={0} end={e?.counter} duration={2} delay={0} />}+
                                            </h1>
                                            <span>{e?.title}</span>
                                        </div>
                                    </Col>
                                </>
                            )
                        })
                    }
                </Row>

            </ScrollTrigger>
        </Container>
    )
}

export default Counter
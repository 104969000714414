import React from "react";
import { CONSTANTS } from "constant/Content";
import { Container, Row, Col } from "reactstrap";
import { BsShieldX } from "react-icons/bs"
import { SlScreenDesktop } from "react-icons/sl"
import { FiSmartphone } from "react-icons/fi"
import { VscRocket } from "react-icons/vsc"

function ServiceIntro() {

  const headingStyles = {
    color: "black",
    textTransform: "uppercase"
  }

  return (
    <>
      <Container className="eachSectionMarginTop service-intro-seciton" data-aos="slide-right" data-aos-duration="1500" data-aos-offset="200">
        <Row className="justify-content-center">
          <Col className="col-lg-5 mr-auto mb-5 p-5 p-md-0 col-12" >
            <span className="d-block text-uppercase lead  p-0 m-0">Featured Services</span>
            <span className="divider my-4"></span>
            <h2 className="mb-4 font-weight-300" style={headingStyles}>The <strong className="font-weight-700">Services</strong> That we are providing</h2>
            <p className="mb-4">
              Emphasise Tech is the best web development company in India. We are specialized in web designing, software development & custom web application development. believes that good online branding starts with a great website because a website represents your business. So, before marketing and branding, we concentrate firstly on web development, software development & custom web application development
              <br></br><br></br>
              Our team focuses always on quality work for web development or custom software development. We are a team of professionals designers & development. has Full-Stack Website Developers which helped us a lot become a top web development company in India.
            </p>
            <button className='themeBtn2 py-2 px-4'>{CONSTANTS.blogSection.blog.button}</button>
          </Col>
          <Col className="col-lg-6  p-5 p-md-0">
            <Row className="mt-5">
              <Col className="col-lg-6 col-md-6 mb-4">
                <div className="service">
                  <span className="icon icon-shield mb-4 d-block"><BsShieldX /></span>
                  <h3>Web & Graphic Designing</h3>
                  <p>Web design services allow you to create a unique website design that's tailored to your business needs or personal</p>
                </div>
              </Col>
              <Col className="col-lg-6 col-md-6 mb-4">
                <div className="service">
                  <span className="icon icon-shield mb-4 d-block"><SlScreenDesktop /></span>
                  <h3>Web Development</h3>
                  <p>We at Emphasise Tech deliver elegant, high-end web development projects that meet and exceed your expectations.</p>
                </div>
              </Col>
              <Col className="col-lg-6 col-md-6 mb-4">
                <div className="service">
                  <span className="icon icon-shield mb-4 d-block"><FiSmartphone /></span>
                  <h3>Mobile App Development</h3>
                  <p>We have decades of experience building mobile applications that meet market needs, enhance brands, expand</p>

                </div>
              </Col>
              <Col className="col-lg-6 col-md-6 mb-4">
                <div className="service">
                  <span className="icon icon-shield mb-4 d-block"><VscRocket /></span>
                  <h3>Digital Marketing</h3>
                  <p>Search engine optimization is the process of enhancing the visibility of a website through organic search engine traffic.</p>
                </div>
              </Col>

            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ServiceIntro;

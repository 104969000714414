import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Homepage from "views/pages/homepage/Index";
import Landing from "views/pages/Landing";
import Login from "views/pages/auth/Login.js";
import AboutUs from "views/pages/abouUs/Index";
import Register from "views/pages/auth/Register.js";
import Contact from "views/pages/contact/Contact";
import Service from "views/pages/service/Index";
import TeamSection from "views/pages/team/Index";
import Dashboard from "views/pages/dashboard/Index";
import UserTable from "views/pages/user/UserTable";
import UserSection from "views/pages/user/Index";
import AddBlog from "views/pages/blogSection/AddBlog";
import BlogList from "views/pages/blogSection/BlogList";
import Blog from "views/pages/blogSection/Blog";
import BlogSection from "views/pages/blogSection/Index";
import DepartmentTable from "views/pages/department/DepartmentTable";
import DepartmentSection from "views/pages/department/Index";
import CertificateSection from "views/pages/certificate/Index";
import QuerySection from "views/pages/query/Index";

import CrudContact from "views/pages/contactCrud/Index";
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Homepage {...props} />} />
      <Route
        path="/dashboard"
        exact
        render={(props) => <Dashboard {...props} />}
      />
      <Route
        path="/queries-section"
        exact
        render={(props) => <QuerySection {...props} />}
        />
        <Route
         path="/c-name"
        exact
        render={(props) => <CrudContact {...props} />}
      />
      <Route
        path="/certificate-section"
        exact
        render={(props) => <CertificateSection {...props} />}
      />
      <Route
        path="/department-section"
        exact
        render={(props) => <DepartmentSection {...props} />}
      />
      <Route
        path="/department-table"
        exact
        render={(props) => <DepartmentTable {...props} />}
      />
      <Route
        path="/user-section"
        exact
        render={(props) => <UserSection {...props} />}
      />
      <Route
        path="/user-table"
        exact
        render={(props) => <UserTable {...props} />}
      />
      <Route
        path="/add-blog"
        exact
        render={(props) => <AddBlog {...props} />}
      />
      <Route
        path="/blog-section"
        exact
        render={(props) => <BlogSection {...props} />}
      />
      <Route
        path="/blog-list"
        exact
        render={(props) => <BlogList {...props} />}
      />
      <Route
        path="/blog"
        exact
        render={(props) => <Blog {...props} />}
      />
      <Route
        path="/landing-page"
        exact
        render={(props) => <Landing {...props} />}
      />
      <Route
        path="/login-page"
        exact
        render={(props) => <Login {...props} />}
      />
      <Route
        path="/about-page"
        exact
        render={(props) => <AboutUs {...props} />}
      />
      <Route
        path="/register-page"
        exact
        render={(props) => <Register {...props} />}
      />
      <Route
        path="/contact-page"
        exact
        render={(props) => <Contact {...props} />}
      />
      <Route
        path="/service-page"
        exact
        render={(props) => <Service {...props} />}
      />
      <Route
        path="/team-page"
        exact
        render={(props) => <TeamSection {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
);

import React, { useEffect, useState } from "react";
import DemoNavbar from "components/DemoNavbar";
import HeroSection from "components/HeroSection";
import MainContent from "components/MainContent";
import ContentBar from "components/ContentBar";
import OnTopBtn from "components/OnTopBtn";
import SimpleFooter from "components/SimpleFooter";
import { useLocation } from "react-router-dom";
import AboutIntro from "../../../components/AboutIntro";

function AboutUs() {
  const [onTop, setOnTop] = useState(false);
  const location = useLocation();
  const pageTitle = location.pathname.split("/")[1];
  const [pageName, setPageName] = useState(pageTitle);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
    setOnTop(false);
  }, [onTop]);

  return (
    <>
      <DemoNavbar />
      <HeroSection />
      <MainContent />
      <AboutIntro pageName={pageName} />
      <ContentBar />
      <OnTopBtn setOnTop={setOnTop} />
      <SimpleFooter />
    </>
  );
}

export default AboutUs;

import React, { useEffect, useState } from 'react'
import { Row, Col, Container, Table, Button } from "reactstrap"
import AddContact from './AddContact'
import { FiEdit } from "react-icons/fi"
import { MdOutlineContentCopy, MdOutlineRestore } from "react-icons/md"
import { AiOutlineDelete, AiOutlineStar } from "react-icons/ai"
import { ContactList, DeletedContact, DisableById, SearchContact, EnableById, AddToFavourite } from 'services/api/contact'
import TContainer from 'components/Toast';
import { toastNotification } from 'components/Toast';
import Loader2 from './Loader2'

function CrudContact() {

    const [userListData, setUserListData] = useState()
    const [show, setShow] = useState(false);
    const [refresh, setRefresh] = useState(false)
    const [contactDetails, setContactDetails] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [favouriteList, setFavouriteList] = useState([])
    const [deletedList, setDeletedList] = useState([])
    const [tab, setTab] = useState(1)
    const [isLoader, setIsLoader] = useState(true)

    console.log(userListData, "userListData")

    useEffect(() => {
        console.log("inside useEffect <<<<<<<<<<<<<<")
        async function fetchData() {
            setIsLoader(true)
            let res = await ContactList()
            if (res?.status === 200) {
                setUserListData(res?.data?.data.filter(e => { return e?.status !== 2 }))
                setDeletedList(res?.data?.data.filter(e => { return e?.status === 2 }))
                setFavouriteList(res?.data?.data.filter(e => { return e?.status === 1 }))
                setIsLoader(false)
            }
        }
        fetchData();
    }, [refresh])


    const handleChange = async (value) => {
        console.log("inside search <<<<<<<<<<", value)
        let res = await SearchContact(value)
        if (res?.status === 200) {
            if (tab === 0) {
                setFavouriteList([...res?.data?.data])
            } else if (tab === 1) {
                setUserListData([...res?.data?.data])
            } else {
                setDeletedList([...res?.data?.data])
            }
        }
    }

    const handleAddUser = () => {
        setContactDetails("")
        setShow(true)
        setIsEdit(false)
    }
    const handleFavourite = async (id) => {
        setIsLoader(true)
        let res = await AddToFavourite(id)
        if (res?.status === 200) {
            setRefresh(!refresh)
            toastNotification("success", res?.data?.message)
            setIsLoader(false)
        }
    }
    const handleEdit = (data) => {
        setShow(true)
        setContactDetails(data)
        setIsEdit(true)
    }
    const handleCopy = (number) => {
        navigator.clipboard.writeText(number);
        toastNotification("success", "Contact Number Copied Successfully")
    }
    const handleDisable = async (id) => {
        setIsLoader(true)
        let res = await DisableById(id)
        if (res?.status === 200) {
            toastNotification("success", res?.data?.message)
            setIsLoader(false)
        }
        setRefresh(!refresh)
    }
    const handleEnable = async (id) => {
        setIsLoader(true)
        let res = await EnableById(id)
        if (res?.status === 200) {
            if (tab === 0) {
                toastNotification("success", "Contact Removed From Favourite")
            } else if (tab === 2) {
                toastNotification("success", res?.data?.message)
            }
            setIsLoader(false)
        }
        setRefresh(!refresh)
    }
    const handleDelete = async (id) => {
        setIsLoader(true)
        let res = await DeletedContact(id)
        if (res?.status === 200) {
            toastNotification("success", res?.data?.message)
            setIsLoader(false)
        }
        setRefresh(!refresh)
    }
    const btnDiv = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0',
        padding: "15px 0"
    }
    const tdStyles = {
        padding: "15px 0",
        paddingLeft: "35px",
        textAlign: "center"
    }
    const iconSize2 = {
        fontSize: "17px"
    }

    return (
        <>
            {
                show ?
                    <AddContact
                        setIsLoader={setIsLoader}
                        refresh={refresh} setRefresh={setRefresh} userListData={userListData} setUserListData={setUserListData} setShow={setShow} show={show} isEdit={isEdit} contactDetails={contactDetails} /> : null
            }
            {
                isLoader ?
                    <Loader2 /> :
                    <Container className="shape-container mt-5 border ">
                        <Row>
                            <Col className="p-0">
                                <div className={tab === 0 ? "btn-block cursorPointer text-white bg-dark py-3 text-center shadow-sm" : "btn-block cursorPointer py-3 textBold text-center shadow-sm "} onClick={() => setTab(0)}>Favourites {`(${favouriteList?.length})`}</div>
                            </Col>
                            <Col className="p-0">
                                <div className={tab === 1 ? "btn-block cursorPointer text-white py-3 text-center bg-dark " : "btn-block cursorPointer py-3 textBold text-center shadow-sm"} onClick={() => setTab(1)}>Total {`(${userListData?.length})`}</div>
                            </Col>
                            <Col className="p-0">
                                <div className={tab === 2 ? "btn-block cursorPointer text-white bg-dark py-3 text-center" : "btn-block cursorPointer py-3 textBold text-center shadow-sm"} onClick={() => setTab(2)}>Deleted {`(${deletedList?.length})`}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='pt-5 w-100 p-0'>
                                    <Row className="mb-3">
                                        <Col className='mb-3 sideByside'>
                                            <input className='pl-3 py-2 ' placeholder='Search here........'
                                                onChange={(e) => handleChange(e.target.value)}
                                            />
                                            <div>
                                                <Button className="px-5" color="primary" onClick={handleAddUser}>Add</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    {
                                        userListData?.length ?
                                            <div className="contactlist-container">
                                                <Table size="sm"  >
                                                    <thead >
                                                        <tr className="">
                                                            <th style={tdStyles}>S.no</th>
                                                            <th style={tdStyles}>Name</th>
                                                            <th style={tdStyles}>Number</th>
                                                            <th style={tdStyles}>Address</th>
                                                            <th style={tdStyles} className='text-center'>Actions </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className=''>
                                                        {
                                                            (tab === 1 ? userListData : tab === 0 ? favouriteList : deletedList)
                                                                ?.map((val, i) => {
                                                                    return (
                                                                        <>
                                                                            <tr key={i} className="user-table-col">
                                                                                <td style={tdStyles}>{i + 1}</td>
                                                                                <td style={tdStyles}>{val.name}</td>
                                                                                <td style={tdStyles}>{val.contactNumber}</td>
                                                                                <td style={tdStyles}>{val.place}</td>
                                                                                <td >
                                                                                    {
                                                                                        tab === 0 ?
                                                                                            <div style={btnDiv}>
                                                                                                <Button onClick={() => handleEnable(val?._id)} style={iconSize2}><AiOutlineDelete /></Button></div> :
                                                                                            tab === 1 ?
                                                                                                <div style={btnDiv}>
                                                                                                    <Button className="ml-3" onClick={() => handleFavourite(val?._id)} style={iconSize2}><AiOutlineStar /></Button>
                                                                                                    <Button className="ml-3" onClick={() => handleEdit(val)}><FiEdit /></Button>
                                                                                                    <Button className="ml-3" onClick={() => handleCopy(val.contactNumber)}><MdOutlineContentCopy /></Button>
                                                                                                    <Button className=" ml-3" onClick={() => handleDisable(val?._id)} style={iconSize2}><AiOutlineDelete /></Button>
                                                                                                </div> :
                                                                                                <div style={btnDiv}>
                                                                                                    <Button onClick={() => handleEnable(val?._id)}><MdOutlineRestore style={iconSize2} /></Button>
                                                                                                    <Button className="ml-3" onClick={() => handleDelete(val?._id)}><AiOutlineDelete style={iconSize2} /></Button>
                                                                                                </div>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })
                                                        }
                                                    </tbody>
                                                    {
                                                        (tab === 1 ? userListData : tab === 0 ? favouriteList : deletedList).length === 0 ?
                                                            <div className='text-center mt-4 w-100 '>
                                                                <p>No Results Found</p>
                                                            </div>
                                                            : null
                                                    }
                                                </Table>
                                            </div>
                                            :
                                            <div className='text-center w-100'>
                                                <p>No Results Found</p>
                                            </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
            }
            <TContainer />
        </>
    )
}


export default CrudContact
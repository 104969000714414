import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import feature1 from "../assets/img/feature_1.png";
import feature2 from "../assets/img/feature_2.png";
import feature3 from "../assets/img/feature_3.png";

function MainFeature() {
  const [featureArray, setFeatureArray] = useState([
    {
      img: feature1,
      title: "Website Design",
      desc: "In order to ensure complete customer satisfaction, this stage ensures reflect your company uniqueness & advantages.",
    },
    {
      img: feature2,
      title: "Web Applications",
      desc: "We provide simple content website application to the most complex web-based internet applications & e-business.",
    },
    {
      img: feature3,
      title: "Support",
      desc: "We are dedicated to offer you the best service with support and updates you can rely on.",
    },
  ]);
  return (
    <Container className="eachSectionMarginTop">
      <Row>
        {featureArray?.map((feature) => {
          return (
            <>
              <Col
                md={4}
                className="p-5"
                data-aos="fade-up"
                data-aos-offset="150"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              >
                <div className="feature-div">
                  <img src={feature?.img} className="img-fluid  mb-5" />
                  <h4 className="textBold mb-3 text-center">
                    {feature?.title}
                  </h4>
                  <p className="text-center">{feature?.desc}</p>
                </div>
              </Col>
            </>
          );
        })}
      </Row>
    </Container>
  );
}

export default MainFeature;

import React from "react";
import { Container, Row, Col } from "reactstrap";
import { CONSTANTS } from "constant/Content";

function MainContent() {
    return (
        <>
                <section className="section mb-5">
                    <Container className="shape-container d-flex align-items-center"
                      data-aos-offset="200"
                      data-aos="slide-left"
                      data-aos-delay="50"
                      data-aos-duration="1000"
                      data-aos-easing="ease-in-out"
                    >
                        <div className="col px-0">
                            <Row className="align-items-center justify-content-center">
                                <Col className="text-center" lg="12">
                                    <h1 className="textBold mb-3">{CONSTANTS.contactPage.main.line1}</h1>
                                    <h3 className="mb-3">
                                    {CONSTANTS.contactPage.main.line2}
                                    </h3>
                                    <p className="lead">
                                    {CONSTANTS.contactPage.main.line3}
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
        </>
    );
}


export default MainContent;

import React, { useState, useRef, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import {
    CardBody,
    Label,
    Card,
    Input,
    Button
} from 'reactstrap'
import * as _ from "lodash"
import { CONSTANTS } from 'constant/Content';
import JoditEditor from 'jodit-react';
import { CreateBlog } from 'services/api/blogs';
import { GetBlogById, UpdateBlog, GetCategoryList } from 'services/api/blogs';
import TContainer from 'components/Toast';
import { toastNotification } from 'components/Toast'
function AddBlog() {

    const editor = useRef(null);
    const history = useHistory();
    const [content, setContent] = useState('');

    const location = useLocation();
    let blogId = new URLSearchParams(location.search).get("id")

    const [blogData, setBlogData] = useState({
        title: "",
        blog_img: "",
        content: "",
        category: "",
        short_desc: ""
    });
    const [categoryList, setCategoryList] = useState()


    useEffect(() => {
        async function fetchData() {
            let res = await GetBlogById(blogId)
            let data = res?.data?.data
            setBlogData(data)
            setContent(data?.content)
            let categorylist = await GetCategoryList()
            setCategoryList(categorylist?.data?.data)
        }
        fetchData()
    }, [])

    function reset() {
        setContent("")
        setBlogData({
            title: "",
            content: "",
            category: ""
        })
    }

    const handleCreateBlog = async () => {
        blogData.content = content;
            if (blogId) {
                let res = await UpdateBlog(blogId, blogData)
                if (res) {
        await toastNotification("success","Blog is updated successfully")
                    reset();
                    history.push("/blog-section")
                }
            } else {
                let res = await CreateBlog(blogData)
                if (res) {
        await toastNotification("success","Post is successfully created")
                    reset();
                    history.push("/blog-section")
                }
            }
    }
    const handleResetPost = () => {
        reset();
    }
    return (
        <>
            <main >
                <section className="section-shaped my-0 center" >
                    <div className='p-2 p-md-5' >
                        <div className='px-5 add-blog-container'>
                            <Card className="shadow-sm add-blog-card">
                                <CardBody >
                                    <h5 className='textBold'>{CONSTANTS.blogSection.addBlog.title}</h5>
                                    <div className='mb-3'>
                                        <Label>{CONSTANTS.blogSection.addBlog.field1.title}</Label>
                                        <Input type="text" placeholder={CONSTANTS.blogSection.addBlog.field1.placeholder}
                                            value={blogData?.title}
                                            onChange={(e) => setBlogData({ ...blogData, title: e.target.value })}
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        <Label>Image link</Label>
                                        <Input type="text"
                                            value={blogData?.blog_img}
                                            onChange={(e) => setBlogData({ ...blogData, blog_img: e.target.value })}
                                        />
                                    </div>
                                    <div className='mb-3 '>
                                        <Label>{CONSTANTS.blogSection.addBlog.field2.title}</Label>
                                        <JoditEditor
                                            ref={editor}
                                            value={content}
                                            onChange={newContent => setContent(newContent)}
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        <Label>{CONSTANTS.blogSection.addBlog.field3.title}</Label>
                                        <Input type="textarea" placeholder={CONSTANTS.blogSection.addBlog.field3.placeholder}
                                            value={blogData?.short_desc}
                                            onChange={(e) => setBlogData({ ...blogData, short_desc: e.target.value })}
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        <Label>{CONSTANTS.blogSection.addBlog.field4.title}</Label><br></br>
                                        <select type="select" className='w-100 py-2 pl-2 blog-field text-muted'
                                            value={blogData?.category}
                                            onChange={(e) => setBlogData({ ...blogData, category: e.target.value })}>
                                            <option value="" selected hidden>{CONSTANTS.blogSection.addBlog.field4.dropdown.title}</option>
                                            {
                                                categoryList?.length && categoryList.map((el) => {
                                                    return (
                                                        <>
                                                            <option key={el} value={el.title}>{el.title}</option>
                                                        </>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='text-center mt-2 mt-md-5'>
                                        <Button color="primary" onClick={handleCreateBlog}>{blogId ? CONSTANTS.blogSection.addBlog.button.updateBlog : CONSTANTS.blogSection.addBlog.button.createBlog}</Button>
                                        <Button color="danger" onClick={handleResetPost}>{CONSTANTS.blogSection.addBlog.button.resetBlog}</Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="separator separator-bottom separator-skew ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                        </svg>
                    </div>
                </section>
            <TContainer/>
            </main>
        </>
    )
}

export default AddBlog
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import TextError from 'components/TextError';
import * as _helper from "../../../utils/Helper"
import * as _ from 'lodash';
import TContainer from 'components/Toast';
import { toastNotification } from 'components/Toast';

import {
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";
import { CreateContact,ContactList,UpdateContact} from 'services/api/contact';

const options = []

function AddContact({ show, setShow, refresh, setRefresh, section,isEdit,contactDetails,setIsLoader }) {

    const [validate, setValidate] = useState(false);
    const [contactVal, setContactVal] = useState(false)
    const [deparmentList, setDeparmentList] = useState()
    const [addressValidation, setAddressValidation] = useState(false)

    const [modelData, setModelData] = useState({
        name: "",
        contactNumber: "",
        place: "",
        favourite:false
    })

    useEffect(() => {
        async function getData() {
            let res = await ContactList()
            setDeparmentList(res?.data?.data)

            res?.data?.data.forEach(e => {
                options.push({ value: e._id, label: e.name })
            })

            setModelData({
                name: contactDetails?.name,
                contactNumber: contactDetails?.contactNumber,
                place: contactDetails?.place,
            })

        }
        getData();
    }, [])


    const handleClose = () => setShow(false);

    const handleSubmit = async () => {
            setValidate(true)
            setIsLoader(true)
            if (modelData.name !== "" && modelData.contactNumber !== "") {
                if(isEdit){
                    let res = await UpdateContact(contactDetails?._id,modelData)
                    console.log(res?.data?.success)
                    if (res?.data?.success) {
                        toastNotification("success",res?.data?.message)
                        setValidate(false)
                        setShow(false)
                        setRefresh(!refresh)
                        setIsLoader(false)
                        return res;
                    } else {
                        setShow(true)
                    }
                }else{
                    let res = await CreateContact(modelData)
                    console.log(res?.data?.success)
                    if (res?.data?.success) {
                        toastNotification("success",res?.data?.message)
                        setValidate(false)
                        setShow(false)
                        setRefresh(!refresh)
                        setIsLoader(false)
                        return res;
                    } else {
                        setShow(true)
                    }
                }
            }

    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <Container >
                        <Row className="justify-content-center ">
                            <Col lg="12 p-0">
                                <div className="text-center mb-4">
                                    <h4 className='text-primary font-weight-bold'> {isEdit? "EDIT CONTACT" : "ADD CONTACT"}</h4>
                                </div>
                                <Form role="form">
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <Input className="pl-4" placeholder="Name" type="text"
                                                value={modelData.name}
                                                onChange={(e) => setModelData({ ...modelData, name: e.target.value })}
                                            />
                                        </InputGroup>
                                        {(validate && !modelData.name) ? <TextError children={'This field is required'} /> : null}
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <Input className="pl-4" placeholder="Contact Number" type="place"
                                                value={modelData.contactNumber}
                                                onChange={(e) => {
                                                    if (!_helper.contactNumberValidation(e.target.value)) {
                                                        setContactVal(true);
                                                    } else {
                                                        setContactVal(false);
                                                    }
                                                    setModelData({ ...modelData, contactNumber: e.target.value.trim() })
                                                }
                                                }
                                            />
                                        </InputGroup>
                                        {(validate && !modelData.contactNumber) ? <TextError children={'This field is required'} /> : null}
                                        {(contactVal && modelData.contactNumber) ? <TextError children={'Please enter valid contact number'} /> : null}
                                    </FormGroup>
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <Input className="pl-4" placeholder="Address" type="textarea"
                                                value={modelData.place}
                                                onChange={(e) => {
                                                    if(e.target.value.length > 10){
                                                        setAddressValidation(true);
                                                    }else{
                                                        setAddressValidation(false)
                                                        setModelData({ ...modelData, place: e.target.value.trim() })
                                                    }
                                                }}
                                           style={{height:"150px"}} />
                                        </InputGroup>
                                        {(validate && !modelData.place) ? <TextError children={'This field is required'} /> : null}
                                        {(addressValidation && modelData.place) ? <TextError children={'max characters in 9'} /> : null}
                                    </FormGroup>
                                    <div className="text-right">
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button
                                            className=""
                                            color="primary"
                                            type="button"
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <TContainer/>
        </>
    );
}

export default AddContact;
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Loder from "components/Loader";
import { GetBlogs, GetBlogsByEmployee } from "services/api/blogs";
import { FiEdit } from "react-icons/fi";
import { CONSTANTS } from "constant/Content";
import { default_img } from "constant/Content";
import HeroSection from "components/HeroSection";
import SimpleFooter from "components/SimpleFooter";
import { AiOutlineDelete } from "react-icons/ai";
import { DeleteBlog } from "services/api/blogs";

function BlogList() {
  const userId = sessionStorage.getItem("userId");

  const [blogData, setBlogData] = useState();
  const [onTop, setOnTop] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (userId) {
        let response = await GetBlogsByEmployee();
        if (response?.data?.success) {
          setBlogData(response?.data?.data);
          setIsLoader(false);
        } else {
          setBlogData("");
          setIsLoader(false);
        }
      } else {
        let response = await GetBlogs();
        if (response?.data?.success) {
          setBlogData(response?.data?.data);
          setIsLoader(false);
        } else {
          setBlogData("");
          setIsLoader(false);
        }
      }
    }
    fetchData();
  }, [refresh]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
    setOnTop(false);
  }, [onTop]);

  const handleDeleteBlog = async (id) => {
    let response = await DeleteBlog(id);
    setRefresh(!refresh);
  };

  return (
    <>
      {userId ? null : <HeroSection />}
      {isLoader ? (
        <Loder loaded={isLoader} />
      ) : (
        <div
          className={
            userId ? "blog-section px-5 mt-5 mt-md-0" : "mt-sm-5 blog-section"
          }
        >
          <div className="blog-container">
            <div className="text-right">
              {userId ? (
                <Link to="add-blog">
                  <Button>{CONSTANTS.blogSection.blogList.button}</Button>
                </Link>
              ) : null}
            </div>
            {blogData?.length ? (
              <Row className="mt-5">
                {blogData?.length &&
                  blogData.map((e, i) => {
                    return (
                      <>
                        <Col md={12} className="blog-horizontal-card">
                          {i % 2 === 0 ? (
                            <Row
                              data-aos={userId ? "" : "slide-right"}
                              data-aos-duration={userId ? "" : "1500"}
                              data-aos-offset={userId ? "" : "150"}
                            >
                              <Col md={7} className="pr-4">
                                <div className="blog-horizontal-content">
                                  <h1 className="text-capitalize font-weight-bold mb-3">
                                    {e?.title}
                                  </h1>
                                  <div className="d-flex">
                                    {userId ? (
                                      <p className="mb-3 blog-category">
                                        {e?.category}
                                      </p>
                                    ) : null}

                                    {userId ? (
                                      <Link to={`/add-blog?id=${e?._id}`}>
                                        <FiEdit className="blog-edit-btn ml-3" />
                                      </Link>
                                    ) : null}
                                    {userId ? (
                                      <AiOutlineDelete
                                        className="blog-delete-btn"
                                        style={{ fontSize: "20px" }}
                                        onClick={() => handleDeleteBlog(e?._id)}
                                      />
                                    ) : null}
                                  </div>
                                  <p className="mb-3">{e?.short_desc}</p>
                                  <Link to={`/blog?id=${e?._id}`}>
                                    <button className="button py-2 px-3 ">
                                      {CONSTANTS.blogSection.blog.button}
                                    </button>
                                  </Link>
                                </div>
                              </Col>
                              <Col md={5} className="">
                                <img
                                  className="blog-img"
                                  src={e?.blog_img ? e.blog_img : default_img}
                                />
                              </Col>
                              {userId ? (
                                <span
                                  className={
                                    e?.status === 0
                                      ? "badge"
                                      : e?.status === 1
                                      ? "bg-success badge"
                                      : "bg-danger badge"
                                  }
                                >
                                  {e?.status === 0
                                    ? ""
                                    : e?.status === 1
                                    ? "New"
                                    : "Deleted"}
                                </span>
                              ) : null}
                            </Row>
                          ) : (
                            <Row
                              data-aos={userId ? "" : "slide-left"}
                              data-aos-duration={userId ? "" : "1500"}
                              data-aos-offset={userId ? "" : "150"}
                            >
                              <Col md={5} className="">
                                <img
                                  className="blog-img"
                                  src={e?.blog_img ? e.blog_img : default_img}
                                />
                              </Col>
                              <Col md={7} className="pr-4">
                                <div className="blog-horizontal-content">
                                  <h1 className="text-capitalize font-weight-bold mb-3">
                                    {e?.title}
                                  </h1>
                                  <div className="d-flex">
                                    {userId ? (
                                      <p className="mb-3 blog-category">
                                        {e?.category}
                                      </p>
                                    ) : null}
                                    {userId ? (
                                      <Link to={`/add-blog?id=${e?._id}`}>
                                        <FiEdit className="blog-edit-btn ml-3" />
                                      </Link>
                                    ) : null}
                                    {userId ? (
                                      <AiOutlineDelete
                                        className="blog-delete-btn"
                                        style={{ fontSize: "20px" }}
                                        onClick={() => handleDeleteBlog(e?._id)}
                                      />
                                    ) : null}
                                  </div>
                                  <p className="mb-3">{e?.short_desc}</p>
                                  <Link to={`/blog?id=${e?._id}`}>
                                    <button className="button py-2 px-3 ">
                                      {CONSTANTS.blogSection.blog.button}
                                    </button>
                                  </Link>
                                </div>
                              </Col>
                              {userId ? (
                                <span
                                  className={
                                    e?.status === 0
                                      ? "badge"
                                      : e?.status === 1
                                      ? "bg-success badge"
                                      : "bg-danger badge"
                                  }
                                >
                                  {e?.status === 0
                                    ? ""
                                    : e?.status === 1
                                    ? "New"
                                    : "Deleted"}
                                </span>
                              ) : null}
                            </Row>
                          )}
                        </Col>
                      </>
                    );
                  })}
              </Row>
            ) : userId ? (
              <p>{CONSTANTS.blogSection.blogList.line1}</p>
            ) : null}
          </div>
        </div>
      )}
      {userId ? null : <SimpleFooter />}
    </>
  );
}

export default BlogList;

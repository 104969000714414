import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Row, Col, Container } from "reactstrap";
import { GetBlogs } from "services/api/blogs";

function Testimonial() {
  const [blogData, setBlogData] = useState();
  const sliderRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      let response = await GetBlogs();
      if (response?.data?.success) {
        setBlogData(response?.data?.data);
      } else {
        setBlogData([]);
      }
    }
    fetchData();
  }, []);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000, // Set the autoplay speed in milliseconds
  };

  const blogImgStyles = {
    height: "80px",
    width: "80px",
    borderRadius: "50%",
  };

  const testmonialArray = [
    {
      name: "Simon Oswal",
      desg: "XYZ Inc. Client",
      msg: "I'm really impressed with the web development company's work. They designed a modern and user-friendly website for my business that perfectly captures our brand's essence. The team was attentive to our needs throughout the process and delivered the project ahead of schedule. Their expertise in both design and functionality is evident in the seamless browsing experience the site offers. I highly recommend their services to anyone looking for top-notch web development.",
      img: require("../assets/img/blog-user.jpg"),
    },
    {
      name: "Dhanraj Singh",
      desg: "Client",
      msg: "I couldn't be happier with the results from the web development company. From the initial consultation to the final launch, their team displayed a deep understanding of our business goals. They transformed our vague ideas into a stunning and fully functional website. Their attention to detail and responsiveness were remarkable; every question and concern were addressed promptly. This company truly goes the extra mile to ensure client satisfaction. Our new website not only looks fantastic but also performs seamlessly across all devices. Partnering with them was a great decision!",
      img: require("../assets/img/Dhanraj.jpg"),
    },
  ];
  return (
    <>
      <section style={{ backgroundColor: "#f9f9f9" }}>
        <Container className="blog-carsouel-container  eachSectionMarginTop pt-5 px-0">
          <div className="mb-5">
            <h2 className="text-center text-uppercase">Testimonial</h2>
          </div>
          {/* <hr className='bg-dark' /> */}
          <Slider className="pt-5" ref={sliderRef} {...settings}>
            {testmonialArray?.length
              ? testmonialArray.map((e, i) => {
                  return (
                    <>
                      <div
                        md={12}
                        className="blog-horizontal-card"
                        style={{ backgroundColor: "white" }}
                      >
                        {
                          <Row>
                            <Col className="p-5">
                              <div className="mb-3 d-flex ">
                                <div className="">
                                  <img src={e?.img} style={blogImgStyles} />
                                </div>
                                <div className="ml-4 ">
                                  {e?.name}
                                  <h4 className="font-weight-500 text-dark h4-0 m-0"></h4>
                                  <span className="font-weight-300">
                                    {e?.desg}
                                  </span>
                                </div>
                              </div>
                              <blockquote>
                                <p>”{e?.msg}“</p>
                              </blockquote>
                            </Col>
                          </Row>
                        }
                      </div>
                    </>
                  );
                })
              : null}
          </Slider>
        </Container>
      </section>
    </>
  );
}

export default Testimonial;
